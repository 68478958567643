import React, { useState } from 'react';
import {
  GoogleReCaptcha,
  withGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import ReCaptchaTerms from './reCaptchaTerms';
import { Paper, Theme } from '@mui/material';
import { Field, reduxForm, submit } from 'redux-form';
import submitForm from './submitForm';
import RenderTextField from '../common/reduxForm/RenderTextField';
import RenderCheckboxField from '../common/reduxForm/RenderCheckboxField';
import validateForm from './validateForm';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  setSignUpFormSubmitResult,
  setSignUpFormDialogShowFlag,
} from '../../actions/signup';
import ConfirmationDialog from './ConfirmationDialog';
import renderMobilePhoneField2 from '../common/reduxForm/renderMobilePhoneField2';
import { green } from '@mui/material/colors';
import { AppState } from '../../reducers';
import { IntlProps } from '../common/types/intlProps';
import { AppReduxFormProps, AppReduxFormSubmit, AppReduxFormValidator } from '../common/types/appReduxForm';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
    margin: theme.spacing(3, 0, 2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));

const TermsOfService = () => {
  return (
    <Typography variant="body2" color="textSecondary" component="span">
      <FormattedMessage id="signup.telxira.terms.text" />
      <Link
        target="blank"
        underline="always"
        color="inherit"
        href="https://telxira.com/wp-content/uploads/2021/02/T_C_tX_English-published.pdf"
      >
        <FormattedMessage id="signup.telxira.terms.of.service" />
      </Link>
      {'.'}
    </Typography>
  );
};

type Props = {
  readonly defaultCountryCode: string;
  readonly signUpFormDialogShow: boolean;
  readonly dispatch: (action: $TSFixMe) => void;
} & IntlProps;

type FormValues = $TSFixMe;
type FormProps = AppReduxFormProps<FormValues, Props>;
export type SignUpFormValidator = AppReduxFormValidator<FormValues, Props>;
export type SignUpFormSubmit = AppReduxFormSubmit<FormValues, Props>;

const SignUpForm = (props: FormProps) => {
  const {
    handleSubmit,
    dispatch,
    submitting,
    error,
    signUpFormDialogShow,
    defaultCountryCode,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(true);

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper} elevation={4}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="body1">
          <FormattedMessage id="signup.dialog.title" />
        </Typography>
        {error && (
          <Collapse in={open}>
            <Alert
              severity="error"
              className={classes.alert}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>
        )}
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(submitForm)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Field
                component={RenderTextField}
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label={<FormattedMessage id="common.label.first.name" />}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={RenderTextField}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label={<FormattedMessage id="common.label.last.name" />}
                name="lastName"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={RenderTextField}
                variant="outlined"
                fullWidth
                name="company"
                label={<FormattedMessage id="common.label.company.name" />}
                id="company"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={RenderTextField}
                variant="outlined"
                required
                fullWidth
                id="email"
                label={<FormattedMessage id="signup.label.email" />}
                name="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={renderMobilePhoneField2}
                defaultCountry={
                  (defaultCountryCode && defaultCountryCode.toLowerCase()) ||
                  'ch'
                }
                autoFormat={false}
                normalize={(value: $TSFixMe) => value.replace(/\s/g, '')}
                variant="outlined"
                required
                fullWidth
                id="mobilePhone"
                label={<FormattedMessage id="signup.label.mobile.number" />}
                name="mobilePhone"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="tcAcceptance"
                component={RenderCheckboxField}
                label={TermsOfService()}
              />
            </Grid>
          </Grid>
          <div className={classes.wrapper}>
            <Button
              onClick={() => dispatch(submit('signUpForm'))}
              fullWidth
              variant="contained"
              disabled={submitting}
              id="gtm-sign-up-button"
            >
              <FormattedMessage id="signup.button.sign.up" />
            </Button>
            {submitting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          {/* @ts-expect-error TODO: Type 'null' is not assignable to type 'void | Promise<void>' */}
          <GoogleReCaptcha onVerify={() => null} />
          <ReCaptchaTerms />
        </form>
      </Paper>
      <ConfirmationDialog open={signUpFormDialogShow} reset={props.reset} />
    </Container>
  );
};

const SignupReduxForm = injectIntl(
  withGoogleReCaptcha(reduxForm({
    form: 'signUpForm',
    onSubmit: submitForm,
    validate: validateForm,
    onSubmitSuccess: (result, dispatch, props) => {
      dispatch(setSignUpFormSubmitResult(result));
      dispatch(setSignUpFormDialogShowFlag(result.result));
    },
  })(SignUpForm)));

export default connect((state: AppState) => {
  return {
    signUpFormDialogShow: state.signup.signUpFormDialogShow,
    signUpFormSubmitResult: state.signup.signUpFormSubmitResult,
    defaultCountryCode: state.app.ipInfo.country_code,
  };
},
  { setSignUpFormSubmitResult, setSignUpFormDialogShowFlag }
)(SignupReduxForm);
