/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Info from '@mui/icons-material/Info';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpContacts = (props: Props) => {
  return (
    <div style={{ padding: 5, marginTop: 15, marginBottom: 15 }}>
      <Typography variant="subtitle2" align="justify">
        <FormattedMessage id="help.contact.19" />
      </Typography>
      <Typography style={{ marginTop: 15, marginBottom: 15 }} variant="body1" component="div">
        {props.fromDashboard ? (
          <Button component={Link} to="/contacts/new" size="small" color="secondary">
            <FormattedMessage id="help.contact.1" />
          </Button>
        ) : (
          <Typography variant={'subtitle2'} style={{ marginBottom: 10, marginTop: 15 }}>
            <FormattedMessage id="help.contact.1" />
          </Typography>
        )}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.contact.2" />
          <br />
          <FormattedMessage id="help.contact.3" />
          <br />
          <br />
          <FormattedMessage id="help.contact.4" />
          <br />
          <Info style={{ verticalAlign: 'bottom' }} color="primary" />
          &nbsp;
          <FormattedMessage id="help.contact.20" />
        </Typography>
      </div>
      <Typography style={{ marginTop: 15 }} variant="body1" component="div">
        {props.fromDashboard ? (
          <Button component={Link} to="/contacts/manage" size="small" color="secondary">
            <FormattedMessage id="help.contact.5" />
          </Button>
        ) : (
          <Typography variant={'subtitle2'} style={{ marginBottom: 10 }}>
            <FormattedMessage id="help.contact.5" />
          </Typography>
        )}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.contact.6" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="help.contact.7" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="help.contact.8" />
        </Typography>
      </div>
      <Typography style={{ marginTop: 15 }} variant="body1" component="div">
        {props.fromDashboard ? (
          <Button component={Link} to="/contacts/import" size="small" color="secondary">
            <FormattedMessage id="help.contact.9" />
          </Button>
        ) : (
          <Typography variant={'subtitle2'} style={{ marginBottom: 10 }}>
            {' '}
            <FormattedMessage id="help.contact.9" />
          </Typography>
        )}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2" component="div">
          <FormattedMessage id="help.contact.10" />
          <br />
          <FormattedMessage id="help.contact.11" />
          <br />
          <FormattedMessage id="help.contact.11.1" />
          <br />
          <Typography variant="body2" component="div">
            <ol>
              <li>
                <FormattedMessage id="help.contact.12" />
              </li>
              <li>
                <FormattedMessage id="help.contact.13" />
              </li>
              <li>
                <FormattedMessage id="help.contact.14" />
              </li>
              <li>
                <FormattedMessage id="help.contact.15" />
              </li>
              <li>
                <FormattedMessage id="help.contact.16" />
              </li>
            </ol>
          </Typography>
        </Typography>
      </div>
      <Typography style={{ marginTop: 15 }} variant="body1" component="div">
        {props.fromDashboard ? (
          <Button component={Link} to="/contacts/importHistory" size="small" color="secondary">
            <FormattedMessage id="help.contact.17" />
          </Button>
        ) : (
          <Typography variant={'subtitle2'} style={{ marginBottom: 10 }}>
            <FormattedMessage id="help.contact.17" />
          </Typography>
        )}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.contact.18" />
        </Typography>
      </div>
      <p></p>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.contact.21" />
        </Typography>
      </div>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.contact.22" />
        </Typography>
      </div>
      <br />
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2" component="div">
          <FormattedMessage id="help.contact.23" />
          <br />

          <Typography variant="body2" component="div">
            <ol>
              <li>
                <FormattedMessage id="help.contact.24" />
              </li>
              <li>
                <FormattedMessage id="help.contact.25" />
              </li>
              <li>
                <FormattedMessage id="help.contact.26" />
              </li>
            </ol>
          </Typography>
        </Typography>
      </div>
    </div>
  );
};

export default HelpContacts;
