import React from 'react';
import { FormattedMessage } from 'react-intl';
import HelpDashboard from './HelpDashboard';
import HelpCampaign from './HelpCampaign';
import HelpContacts from './HelpContacts';
import HelpAccount from './HelpAccount';
import HelpAdminPackages from './HelpAdminPackages';
import HelpChat from './HelpChat';
import stylesConfig from '../../config';
import HelpQuickTestSms from './HelpQuickTestSms';
import HelpEmail2Sms from './HelpEmail2Sms';
import HelpAdminCustomers from './HelpAdminCustomers';
import HelpAdminUsers from './HelpAdminUsers';
import HelpAdminFinance from './HelpAdminFinance';
import HelpAdminBalanceOverview from './HelpAdminBalanceOverview';
import HelpAdminTools from './HelpAdminTools';
import HelpAdminEmail2Sms from './HelpAdminEmail2Sms';
import HelpAdminMtApi from './HelpAdminMtApi';
import HelpAdminCdrs from './HelpAdminCdrs';
import HelpAdminInboundNumbers from './HelpAdminInboundNumbers';
import HelpAdminMnp from './HelpAdminMnp';
import HelpAdminReports from './HelpAdminReports';
import HelpAdminResellers from './HelpAdminResellers';

const helpIndex = [
  {
    location: '/administration/customers',
    caption: <FormattedMessage id="help.administration.customers.title" />,
    component: <HelpAdminCustomers company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/users',
    caption: <FormattedMessage id="help.administration.users.title" />,
    component: <HelpAdminUsers company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/finance',
    caption: <FormattedMessage id="help.administration.finance.title" />,
    component: <HelpAdminFinance company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/balance',
    caption: <FormattedMessage id="help.administration.balance.title" />,
    component: <HelpAdminBalanceOverview company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/tools',
    caption: <FormattedMessage id="help.administration.tools.title" />,
    component: <HelpAdminTools company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/reseller',
    caption: <FormattedMessage id="help.administration.resellers.title" />,
    component: <HelpAdminResellers company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/email2sms',
    caption: <FormattedMessage id="help.administration.email2sms.title" />,
    component: <HelpAdminEmail2Sms company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/mtApi',
    caption: <FormattedMessage id="help.administration.mtApi.title" />,
    component: <HelpAdminMtApi company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/cdrs',
    caption: <FormattedMessage id="help.administration.cdrs.title" />,
    component: <HelpAdminCdrs company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/inboundNumbers',
    caption: <FormattedMessage id="help.administration.inboundNumbers.title" />,
    component: <HelpAdminInboundNumbers company={stylesConfig.COMPANY} />,
  },
  {
    location: '/administration/pricelistTemplates',
    caption: <FormattedMessage id="help.administration.pricelistTemplates.title" />,
    component: <HelpAdminPackages company={stylesConfig.COMPANY} />,
  },
  {
    location: '/dashboard',
    caption: <FormattedMessage id="help.dashboard.title" values={{ company: stylesConfig.COMPANY }} />,
    component: <HelpDashboard company={stylesConfig.COMPANY} helpEmail={stylesConfig.HELP_EMAIL} />,
  },
  {
    location: '/smsCampaign/new',
    caption: <FormattedMessage id="help.campaign.new.title" />,
    component: <HelpCampaign company={stylesConfig.COMPANY} />,
  },
  {
    location: '/smsCampaign/history',
    caption: <FormattedMessage id="help.campaign.history.title" />,
    component: <HelpCampaign company={stylesConfig.COMPANY} />,
  },
  {
    location: '/contacts/new',
    caption: <FormattedMessage id="help.contacts.new.title" />,
    component: <HelpContacts company={stylesConfig.COMPANY} />,
  },
  {
    location: '/contacts/manage',
    caption: <FormattedMessage id="help.contacts.manage.title" />,
    component: <HelpContacts company={stylesConfig.COMPANY} />,
  },
  {
    location: '/contacts/importHistory',
    caption: <FormattedMessage id="help.contacts.import.history.title" />,
    component: <HelpContacts company={stylesConfig.COMPANY} />,
  },
  {
    location: '/contacts/import',
    caption: <FormattedMessage id="help.contacts.import.title" />,
    component: <HelpContacts company={stylesConfig.COMPANY} />,
  },
  {
    location: '/account/profile',
    caption: <FormattedMessage id="help.account.profile.title" />,
    component: <HelpAccount company={stylesConfig.COMPANY} />,
  },
  {
    location: '/account/pricing',
    caption: <FormattedMessage id="help.account.profile.title" />,
    component: <HelpAccount company={stylesConfig.COMPANY} />,
  },
  {
    location: '/account/balance',
    caption: <FormattedMessage id="help.account.profile.title" />,
    component: <HelpAccount company={stylesConfig.COMPANY} />,
  },
  {
    location: '/account/invoices',
    caption: <FormattedMessage id="help.account.invoices.title" />,
    component: <HelpAccount company={stylesConfig.COMPANY} />,
  },
  {
    location: '/smschat',
    caption: <FormattedMessage id="nav.smschat" />,
    component: <HelpChat company={stylesConfig.COMPANY} />,
  },
  {
    location: '/quickTestSms',
    caption: <FormattedMessage id="nav.quickTestSms" />,
    component: <HelpQuickTestSms company={stylesConfig.COMPANY} />,
  },
  {
    location: '/email2sms',
    caption: <FormattedMessage id="nav.email2sms" />,
    component: <HelpEmail2Sms company={stylesConfig.COMPANY} />,
  },
  {
    location: '/account/tokens',
    caption: <FormattedMessage id="nav.account.tokens" />,
    component: <HelpAccount company={stylesConfig.COMPANY} />,
  },
  {
    location: '/account/cdrs',
    caption: <FormattedMessage id="nav.account.cdrs" />,
    component: <HelpAccount company={stylesConfig.COMPANY} />,
  },
  {
    location: '/mnp/mobileCountryCodes',
    caption: <FormattedMessage id="help.administration.mnp.title" />,
    component: <HelpAdminMnp company={stylesConfig.COMPANY} />,
  },
  {
    location: '/mnp/mobileNetworkNames',
    caption: <FormattedMessage id="help.administration.mnp.title" />,
    component: <HelpAdminMnp company={stylesConfig.COMPANY} />,
  },
  {
    location: '/mnp/lookupHistory',
    caption: <FormattedMessage id="help.administration.mnp.title" />,
    component: <HelpAdminMnp company={stylesConfig.COMPANY} />,
  },
  {
    location: '/mnp/lookupNumber',
    caption: <FormattedMessage id="help.administration.mnp.title" />,
    component: <HelpAdminMnp company={stylesConfig.COMPANY} />,
  },
  {
    location: '/reports/dailyReports',
    caption: <FormattedMessage id="help.administration.reports.title" />,
    component: <HelpAdminReports company={stylesConfig.COMPANY} />,
  },
  {
    location: '/reports/trafficReports',
    caption: <FormattedMessage id="help.administration.reports.title" />,
    component: <HelpAdminReports company={stylesConfig.COMPANY} />,
  },
];

export default helpIndex;
