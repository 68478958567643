import { AnyAction } from 'redux';
import { MenuEntry } from '../types/menu';

interface Option {
  readonly value: string;
  readonly name: string;
}

interface AppState {
  mobileMenuOpen: boolean;
  ipInfo: {
    country: string;
    country_code?: string;
    ip?: string;
    checkTime?: number;
    error?: $TSFixMe;
  };
  menu: MenuEntry[];
  menuOpen: string[];
  menuSelected: string;
  loadingMenu: boolean;
  settings: $TSFixMe;
  loadingSettings: boolean;
  logo: string;
  statusFilterOptions: Option[];
  serviceFilterOptions: Option[];
  showPerformanceDrawer: boolean;
  unauthorizedFilterOptions: Option[];
  smsStatusFilterOptions: Option[];
  customerTypeFilterOptions: Option[];
  billingCycleTypeOptions: Option[];
  userTagsOptions: Option[];
  pricingFeeTypesOptions: Option[];
}

const initialState: AppState = {
  mobileMenuOpen: false,
  ipInfo: { country: 'PL' },
  menu: [],
  menuOpen: ['nav.dashboard'],
  menuSelected: 'nav.dashboard',
  loadingMenu: true,
  settings: {},
  loadingSettings: true,
  logo: '',
  statusFilterOptions: [],
  serviceFilterOptions: [],
  showPerformanceDrawer: false,
  unauthorizedFilterOptions: [],
  smsStatusFilterOptions: [],
  customerTypeFilterOptions: [],
  billingCycleTypeOptions: [],
  userTagsOptions: [],
  pricingFeeTypesOptions: [],
};

export default function reducer(state: AppState = initialState, action: AnyAction): AppState {
  switch (action.type) {
    case 'GET_IP_INFO': {
      const infoObject = {
        ...action.payload,
        checkTime: new Date().valueOf(),
      };
      return {
        ...state,
        ipInfo: { ...state.ipInfo, ...infoObject },
      };
    }
    case 'GET_IP_INFO_FAILED': {
      return {
        ...state,
        ipInfo: { ...state.ipInfo, error: action.error },
      };
    }
    case 'GET_MENU_LOADING': {
      return {
        ...state,
        loadingMenu: true,
      };
    }
    case 'SET_MENU': {
      return {
        ...state,
        menu: action.menu,
      };
    }
    case 'SET_MENU_SELECTED': {
      return {
        ...state,
        menuSelected: action.payload,
      };
    }
    case 'SET_MENU_OPEN': {
      return {
        ...state,
        menuOpen: action.payload,
      };
    }
    case 'GET_MENU_SUCCESS': {
      return {
        ...state,
        menu: action.payload,
        loadingMenu: false,
      };
    }
    case 'GET_MENU_FAILED': {
      return {
        ...state,
        loadingMenu: false,
      };
    }
    case 'GET_STATS_LOADING': {
      return {
        ...state,
        loadingSettings: true,
      };
    }
    case 'GET_STATS_SUCCESS': {
      return {
        ...state,
        settings: action.payload,
        loadingSettings: false,
      };
    }
    case 'GET_STATS_FAILED': {
      return {
        ...state,
        loadingSettings: false,
      };
    }
    case 'OPEN_MOBILE_MENU': {
      return {
        ...state,
        mobileMenuOpen: true,
      };
    }
    case 'CLOSE_MOBILE_MENU': {
      return {
        ...state,
        mobileMenuOpen: false,
      };
    }
    case 'SET_LOGO': {
      return {
        ...state,
        logo: action.payload,
      };
    }
    case 'SET_STATUS_FILTER_OPTIONS': {
      return {
        ...state,
        statusFilterOptions: action.payload,
      };
    }
    case 'SET_SERVICE_FILTER_OPTIONS': {
      return {
        ...state,
        serviceFilterOptions: action.payload,
      };
    }
    case 'SET_PERFORMANCE_DRAWER_SHOW_FLAG': {
      return {
        ...state,
        showPerformanceDrawer: action.payload,
      };
    }
    case 'SET_UNAUTHORIZED_STATUS_FILTER_OPTIONS': {
      return {
        ...state,
        unauthorizedFilterOptions: action.payload,
      };
    }
    case 'SET_SMS_STATUS_FILTER_OPTIONS': {
      return {
        ...state,
        smsStatusFilterOptions: action.payload,
      };
    }
    case 'SET_CUSTOMER_TYPE_FILTER_OPTIONS': {
      return {
        ...state,
        customerTypeFilterOptions: action.payload,
      };
    }
    case 'SET_BILLING_CYCLE_TYPE_OPTIONS': {
      return {
        ...state,
        billingCycleTypeOptions: action.payload,
      };
    }
    case 'SET_USER_TAGS_OPTIONS': {
      return {
        ...state,
        userTagsOptions: action.payload,
      };
    }
    case 'SET_PRICING_FEE_TYPES_OPTIONS': {
      return {
        ...state,
        pricingFeeTypesOptions: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
