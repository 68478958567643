import React from 'react';
import { FormattedMessage } from 'react-intl';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ListIcon from '@mui/icons-material/ListAlt';

interface Row {
  readonly mailFrom: string;
  readonly mailTo: string;
  readonly message: string;
  readonly status: string;
  readonly receivedBy: string;
  readonly customerUuid: string;
  readonly createDateTime: string;
};

// eslint-disable-next-line
const columns = (role: $TSFixMe, unauthorizedFilterOptions: $TSFixMe[], showSnackBarMsg: Function) => {
  const columns = [];
  if (role['SUPER_ADMIN'])
    columns.push({
      id: 'customerUuid',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="administration.email2sms.accessLog.table.customer.uuid" />,
      allowSort: true,
      visible: true,
      allowFilter: true,
    });
  columns.push(
    {
      id: 'createDateTime',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="administration.email2sms.accessLog.table.create.date.time" />,
      allowSort: true,
      visible: true,
      dateFiltering: true,
      timeFiltering: true,
    },
    {
      id: 'mailFrom',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="administration.email2sms.accessLog.table.mail.from" />,
      allowSort: true,
      visible: true,
      allowFilter: true,
    },
    {
      id: 'mailTo',
      numeric: false,
      disablePadding: false,
      label: (
        <FormattedMessage id="administration.email2sms.accessLog.table.mail.to" />
      ),
      allowSort: true,
      visible: true,
      allowFilter: true,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="common.label.status" />,
      allowSort: true,
      visible: true,
      allowFilter: true,
      filterOptions: unauthorizedFilterOptions,
      render: (row: Row) => <Chip label={row.status} variant="outlined" style={{ height: 20 }} />
    },
    {
      id: 'receivedBy',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="administration.email2sms.accessLog.table.received.by" />,
      allowSort: true,
      visible: true,
      allowFilter: false,
      render: (row: Row) => {
        return (
          <Tooltip title={row.receivedBy} placement="top-start">
            <IconButton
              style={{ height: 32, width: 32, padding: 0 }}
              size="large"
              onClick={() => {
                navigator.clipboard.writeText(row.receivedBy);
                showSnackBarMsg(
                  <FormattedMessage id="administration.email2sms.accessLog.details.are.copied" />,
                  'success'
                );
              }}
            >
              <ListIcon color="primary" />
            </IconButton>
          </Tooltip>
        )
      }
    },
  );
  return columns;
};

export default columns;
