import sunrise from './sunrise.theme';
import telxira from './telxira.theme';
import gms from './gms.theme';

const createAppTheme = (themeName?: string) => {
  switch (themeName) {
    case 'sunrise':
      return sunrise;
    case 'gms':
      return gms;
    default:
      return telxira;
  }
};

export default createAppTheme(process.env.REACT_APP_THEME);
