import en from './locales/en.json';
import de from './locales/de.json';
// import pl from './locales/pl.json';
import { SupportedLang } from './supportedLang';

type Messages = {
  [key in SupportedLang]: {
    [key: string]: any;
  };
};

export const messages: Messages = {
  [SupportedLang.EN]: {
    ...en.menu,
    ...en.contacts,
    ...en.datatable,
    ...en.components,
    ...en.dialogs,
    ...en.campaign,
    ...en.account,
    ...en.data,
    ...en.help,
    ...en.login,
    ...en.tables,
    ...en.finances,
    ...en.pricing,
    ...en.forms,
    ...en.dashboard,
    ...en.administration.customers,
    ...en.administration.resellers,
    ...en.administration.pricelistTemplates,
    ...en.administration.users,
    ...en.administration.balance,
    ...en.administration.whatsapp,
    ...en.administration.demoRequests,
    ...en.administration.email2sms,
    ...en.administration.mtApi,
    ...en.administration.inboundNumbers,
    ...en.dates,
    ...en.salutations,
    ...en.currency,
    ...en.administration.settings,
    ...en.administration.tools,
    ...en.datepicker,
    ...en.gender,
    ...en.signup,
    ...en.smschat,
    ...en.email2sms,
    ...en.email2sms.ubs,
    ...en.email2sms.ubs.countryOverride,
    ...en.email2sms.ubs.mo,
    ...en.email2sms.ubs.optout,
    ...en.email2sms.ubs.performance,
    ...en.email2sms.ubs.sender,
    ...en.email2sms.ubs.settings,
    ...en.email2sms.ubs.spam,
    ...en.email2sms.ubs.tags,
    ...en.email2sms.ubs.whitelist,
    ...en.email2sms.ubs.messageLogs,
    ...en.testAccount,
    ...en.testAccount.dialog,
    ...en.testAccount.numbers,
    ...en.testAccount.welcome,
    ...en.cdrs,
    ...en.common,
    ...en.reports.dailyReports,
    ...en.administration.bankAccount,
    ...en.reports.trafficReports,
    ...en.quickTestSms,
    ...en.mnp.mobileNetworkNames,
    ...en.mnp.mobileCountryCodes,
    ...en.mnp.lookup,
    ...en.tests,
    ...en.status,
    ...en.settings.resellerCosts,
    ...en.settings.masterDestination,
    ...en.tests,
  },
  [SupportedLang.DE]: {
    ...de.menu,
    ...de.contacts,
    ...de.datatable,
    ...de.components,
    ...de.dialogs,
    ...de.campaign,
    ...de.account,
    ...de.data,
    ...de.help,
    ...de.login,
    ...de.tables,
    ...de.finances,
    ...de.pricing,
    ...de.forms,
    ...de.dashboard,
    ...de.administration.customers,
    ...de.administration.resellers,
    ...de.administration.pricelistTemplates,
    ...de.administration.users,
    ...de.administration.balance,
    ...de.administration.whatsapp,
    ...de.administration.demoRequests,
    ...de.administration.email2sms,
    ...de.administration.mtApi,
    ...de.administration.inboundNumbers,
    ...de.dates,
    ...de.salutations,
    ...de.currency,
    ...de.administration.settings,
    ...en.administration.tools,
    ...de.datepicker,
    ...de.gender,
    ...de.signup,
    ...de.smschat,
    ...de.email2sms,
    ...de.email2sms.ubs,
    ...de.email2sms.ubs.countryOverride,
    ...de.email2sms.ubs.mo,
    ...de.email2sms.ubs.optout,
    ...de.email2sms.ubs.performance,
    ...de.email2sms.ubs.sender,
    ...de.email2sms.ubs.settings,
    ...de.email2sms.ubs.spam,
    ...de.email2sms.ubs.tags,
    ...de.email2sms.ubs.whitelist,
    ...de.email2sms.ubs.messageLogs,
    ...de.testAccount,
    ...de.testAccount.dialog,
    ...de.testAccount.numbers,
    ...de.testAccount.welcome,
    ...de.cdrs,
    ...de.common,
    ...de.reports.dailyReports,
    ...de.administration.bankAccount,
    ...de.reports.trafficReports,
    ...de.quickTestSms,
    ...de.mnp.mobileNetworkNames,
    ...de.mnp.mobileCountryCodes,
    ...de.mnp.lookup,
    ...de.tests,
    ...de.status,
    ...de.settings.resellerCosts,
    ...de.settings.masterDestination,
    ...de.tests,
  },
  // pl: {
  //   ...pl.menu,
  //   ...pl.contacts,
  //   ...pl.datatable,
  //   ...pl.components,
  //   ...pl.dialogs,
  //   ...pl.campaign,
  //   ...pl.account,
  //   ...pl.data,
  //   ...pl.help,
  //   ...pl.login,
  //   ...pl.tables,
  //   ...pl.finances,
  //   ...pl.pricing,
  //   ...pl.forms,
  //   ...pl.dashboard,
  //   ...pl.administration.customers,
  //   ...pl.administration.packages,
  //   ...pl.administration.users,
  //   ...pl.administration.balance,
  //   ...pl.dates,
  //   ...pl.salutations,
  //   ...pl.currency,
  //   ...pl.administration.settings,
  //   ...pl.datepicker,
  //   ...pl.common
  // },
};
