import React from 'react';
import { connect } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './menuEntryStyle';
import { setMenu } from '../actions/app';
import NewMessageBadge from '../components/smschat/NewMessageBadge';
import { AppState } from '../reducers';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(styles);

export interface MenuItem {
  readonly label: JSX.Element;
  readonly icon?: JSX.Element;
  readonly link: string;
  readonly header?: JSX.Element;
  readonly selected: boolean;
  readonly id: string;
  readonly open: boolean;
  readonly parent?: string;
  readonly subMenu?: MenuItem[];
}

type Props = {
  readonly menuData: MenuItem;
  readonly setMenu: (menuItem: MenuItem) => void;
  readonly totalUnread: $TSFixMe;
};

const MainMenuEntry = ({
  menuData,
  setMenu,
  totalUnread
}: Props) => {
  const classes = useStyles();

  const newMessagesCount = Object.values(totalUnread)
    .map((value: $TSFixMe) => Math.max(0, value))
    .reduce((acc, value) => acc + value, 0);

  let subEntries = null;
  if (menuData.subMenu) {
    subEntries = menuData.subMenu.map((subEntry, index) => {
      return (
        <ListItem
          onClick={() => setMenu(subEntry)}
          key={index}
          button
          className={classNames(classes.nested, {
            [classes.nestedOpen]: menuData.open,
            [classes.selected]: subEntry.selected,
          })}
        >
          <ListItemText
            primary={
              <Typography
                variant="body2"
                noWrap
                className={classNames({
                  [classes.selected]: subEntry.selected,
                })}
              >
                {subEntry.label}
              </Typography>
            }
          />
        </ListItem>
      );
    });
  }

  const menu = (
    <ListItem
      button
      onClick={() => setMenu(menuData)}
      style={{ height: '45px', paddingLeft: 15, paddingRight: 0 }}
      className={classNames(
        { [classes.link]: !menuData.open },
        { [classes.open]: menuData.open }
      )}
    >
      <ListItemIcon
        className={classes.arrowColor}
        style={{ margin: 0, padding: 0 }}
      >
        {menuData.icon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography noWrap variant="body2">
            {menuData.label}
          </Typography>
        }
      />
      {subEntries && subEntries.length > 0 && (
        <ExpandMoreIcon
          className={classNames(
            classes.expand,
            { [classes.expandOpen]: menuData.open },
            classes.arrowColor
          )}
        />
      )}
      {menuData.id === 'nav.smschat' && (
        <ListItemSecondaryAction>
          <NewMessageBadge badgeContent={newMessagesCount} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );

  return (
    <div>
      {menu}

      <Collapse
        component="li"
        in={menuData.open}
        timeout="auto"
        unmountOnExit
      >
        {subEntries && (
          <List disablePadding dense>
            {subEntries}
          </List>
        )}
      </Collapse>
    </div>
  );
}

function mapStateToProps(state: AppState) {
  return {
    menuOpen: state.app.menuOpen,
    menuSelected: state.app.menuSelected,
    totalUnread: state.chat.totalUnread,
  };
}

export default connect(mapStateToProps, { setMenu })(MainMenuEntry);
