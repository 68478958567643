import { AnyAction } from 'redux';

interface StatisticsState {
  contacts: {
    personalCount?: number;
    totalCount?: number;
  };
  stats: {
    userCount?: number;
    customerCount?: number;
    sendSmsCount?: number;
    campaignCount?: number;
    revenueDaily?: number;
    revenueMonthly?: number;
    revenueDailyYesterday?: number;
    volumeDaily?: number;
    volumeMonthly?: number;
    volumeDailyYesterday?: number;
    currency?: string;
    monthlyTraffic: Array<number | null>;
    monthlyTrafficLoading: boolean;
    dailyTraffic: Array<number | null>;
    dailyTrafficLoading: boolean;
  };
  campaigns: {
    lastDelivered?: number;
    lastName?: string;
    lastSent?: number;
    nextContactCount?: number;
    nextName?: string;
    runningDelivered?: number;
    runningName?: string;
    runningSent?: number;
  };
  balanceSummary: $TSFixMe[];
  balanceAllPaid?: number;
  balanceCurrency?: string;
  data?: $TSFixMe;
}

const initialState: StatisticsState = {
  contacts: {
    personalCount: undefined,
    totalCount: undefined,
  },
  stats: {
    userCount: undefined,
    customerCount: undefined,
    sendSmsCount: undefined,
    campaignCount: undefined,
    revenueDaily: undefined,
    revenueMonthly: undefined,
    revenueDailyYesterday: undefined,
    volumeDaily: undefined,
    volumeMonthly: undefined,
    volumeDailyYesterday: undefined,
    currency: '',
    monthlyTraffic: [],
    monthlyTrafficLoading: false,
    dailyTraffic: [],
    dailyTrafficLoading: false,
  },
  campaigns: {
    lastDelivered: 0,
    lastName: undefined,
    lastSent: 0,
    nextContactCount: 0,
    nextName: undefined,
    runningDelivered: 0,
    runningName: undefined,
    runningSent: 0,
  },
  balanceSummary: [],
  balanceAllPaid: 0,
  balanceCurrency: '',
};

export default function reducer(state: StatisticsState = initialState, action: AnyAction): StatisticsState {
  switch (action.type) {
    case 'GET_COUNTS_CONTACTS_SUCCESS': {
      return { ...state, contacts: action.data };
    }
    case 'GET_FAILED': {
      return {
        ...state,
        data: {},
      };
    }
    case 'GET_STATISTICS_USER': {
      return {
        ...state,
        stats: { ...state.stats, ...action.data },
      };
    }
    case 'GET_REVENUE': {
      return {
        ...state,
        stats: { ...state.stats, ...action.data },
      };
    }
    case 'GET_STATISTICS_CAMPAIGN': {
      return {
        ...state,
        campaigns: action.data,
      };
    }
    case 'SET_MONTHLY_CHART_LOADING': {
      return {
        ...state,
        stats: { ...state.stats, monthlyTrafficLoading: true },
      };
    }
    case 'SET_MONTHLY_CHART_DATA': {
      return {
        ...state,
        stats: {
          ...state.stats,
          monthlyTraffic: action.data,
          monthlyTrafficLoading: false,
        },
      };
    }
    case 'SET_DAILY_CHART_LOADING': {
      return {
        ...state,
        stats: { ...state.stats, dailyTrafficLoading: true },
      };
    }
    case 'SET_DAILY_CHART_DATA': {
      return {
        ...state,
        stats: {
          ...state.stats,
          dailyTraffic: action.data,
          dailyTrafficLoading: false,
        },
      };
    }
    case 'SET_BALANCE_TABLE_SUMMARY': {
      return {
        ...state,
        balanceSummary: action.data,
      };
    }
    case 'SET_BALANCE_ALL_PAID': {
      return {
        ...state,
        balanceAllPaid: action.data,
      };
    }
    case 'SET_BALANCE_CURRENCY': {
      return {
        ...state,
        balanceCurrency: action.data,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
