import { AnyAction } from 'redux';
import { AccountProfile } from '../types/accountProfile';

export interface LoginState {
  authenticated: boolean;
  failed: boolean;
  userData: AccountProfile;
  isLoading: boolean;
  smsVerificationDialogShow: boolean;
}

const initialState: LoginState = {
  authenticated: false,
  failed: false,
  userData: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    customerName: '',
    authorizations: {},
    customerCountry: '',
    customerRegion: '',
    customerCity: '',
    customerAddress: '',
    customerPostalCode: '',
    message: '',
    chatAgent: false,
    hasWB: false,
    chatAgentStatus: undefined,
    customerId: undefined,
    customerUuid: '',
    permissions: {},
    isPasswordExpired: false,
    userName: '',
    token: '',
    useStaticSenderId: false,
    _links: {
      self: { href: '' },
      user: { href: '' },
      logo: { href: '' },
      reseller: { href: '' },
      appearance: { href: '' },
      account_info: { href: '' },
    },
  },
  isLoading: false,
  smsVerificationDialogShow: false,
};

export default function reducer(state: LoginState = initialState, action: AnyAction): LoginState {
  switch (action.type) {
    case 'REFRESH_LOGIN_PAGE': {
      return {
        ...state,
        authenticated: false,
        // @ts-expect-error TODO: type { message: string } is missing, but it's defined
        userData: {
          message: 'empty',
        },
      };
    }

    case 'IS_AUTHENTICATED': {
      const { payload } = action;
      return {
        ...state,
        authenticated: true,
        failed: false,
        userData: {
          ...state.userData,
          ...payload,
        },
      };
    }
    case 'IS_NOT_AUTHENTICATED': {
      return {
        ...state,
        authenticated: false,
      };
    }
    case 'LOGIN_SUCCESS': {
      const { payload } = action;
      return {
        ...state,
        authenticated: true,
        failed: false,
        userData: {
          ...state.userData,
          ...payload,
        },
        isLoading: false,
      };
    }
    case 'LOGIN_FAILED': {
      return {
        ...state,
        authenticated: false,
        failed: true,
        userData: action.payload,
        isLoading: false,
      };
    }
    case 'CHANGE_PW_FAILED': {
      const { payload } = action;
      return {
        ...state,
        authenticated: true,
        failed: true,
        userData: {
          ...state.userData,
          ...payload,
        },
        isLoading: false,
      };
    }
    case 'LOGIN_EMPTY': {
      return {
        ...state,
        authenticated: false,
        failed: true,
        userData: action.payload,
      };
    }
    case 'LOGIN_START': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'LOGIN_STOP': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'CLEAN_FAILED': {
      return {
        ...state,
        failed: false,
      };
    }
    case 'SET_SMS_VERIFICATION_CODE_DIALOG_SHOW': {
      return {
        ...state,
        smsVerificationDialogShow: action.payload,
      };
    }
    case 'SMS_VERIFICATION_FAILED': {
      return {
        ...state,
        authenticated: false,
        failed: true,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
