/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpQuickTestSms = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography variant="body1" style={{ marginTop: 15, marginBottom: 10 }}>
        {props.fromDashboard ? (
          <Button component={Link} to="/quickTestSms" size="small" color="secondary">
            <FormattedMessage id="nav.quickTestSms" />
          </Button>
        ) : undefined}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.quicktest.1" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.quicktest.2" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.quicktest.3" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.quicktest.4" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.quicktest.5" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.quicktest.6" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.quicktest.7" />
        </Typography>
      </div>
    </div>
  );
};

export default HelpQuickTestSms;
