import { appConfig } from '../config';
import { AnyAction } from 'redux';

export const searchResultsType = {
  ALL: 0,
  CHATS: 1,
  CONTACTS: 2,
};

export const payloadType = {
  IMAGE: 'image',
  VIDEO: 'video',
  DOC: 'document',
  TEXT: 'text',
  CONTACT: 'contact',
  AUDIO: 'audio',
};

export const attachmentRules = [
  {
    type: payloadType.VIDEO,
    limit: 16,
    info:
      'The maximum file size allowed for all media (photos, videos or voice messages) to be sent or forwarded through WhatsApp is 16 MB on all platforms.',
  },
  {
    type: payloadType.IMAGE,
    limit: 16,
    info:
      'The maximum file size allowed for all media (photos, videos or voice messages) to be sent or forwarded through WhatsApp is 16 MB on all platforms.',
  },
  {
    type: payloadType.DOC,
    limit: 100,
    info: 'For documents, the maximum file size allowed is 100 MB.',
  },
];

const smsIcon = require('../chats/sms.png');

export const validSize = (size: number, type: string) => {
  const rule = attachmentRules.filter((value) => value.type === type)[0];
  return size / 1024 / 1024 < rule.limit;
};

const baseChatHeight = 426;
const chatMessengerSelectorHeight = 64;

interface ChatState {
  numbers: $TSFixMe[];
  contacts: $TSFixMe[];
  chats: $TSFixMe[];
  agents: $TSFixMe[];
  clientNumber: string;
  showAgents: boolean;
  contactsPageSize: number;
  contactsAllElements: number;
  chatsPageSize: number;
  messagesPageSize: number;
  messagesAllElements: number;
  selectedContact: $TSFixMe;
  number: string;
  channel: string;
  loading: boolean;
  error: string;
  serviceDisabled: boolean;
  mobileMessageView: boolean;
  messages: $TSFixMe[];
  to: string;
  text: string;
  sending: boolean;
  contactSuggestions: $TSFixMe[];
  payloadType: string;
  attachment: {
    uploading: boolean;
    data?: $TSFixMe;
    id?: $TSFixMe;
    mpId?: $TSFixMe;
    validSize: boolean;
    failed: boolean;
    source?: $TSFixMe;
    error?: $TSFixMe;
  },
  attachDialOpen: boolean;
  loadingMessages: boolean;
  newMessages: {
    SMS: $TSFixMe[];
    WB: $TSFixMe[];
  };
  notificationBody: string;
  notificationIgnore: boolean;
  notificationIcon: $TSFixMe;
  scrollToBottom: boolean;
  totalUnread: $TSFixMe;
  searchResults: number;
  filter: string;
  loadingChatContacts: boolean;
  addNewContactDialogOpen: boolean;
  addNewUserFullFormOpen: boolean;
  validationError: boolean;
  validationMessage: string;
  messageMaxLength: number;
  chatMessageLineHeight: number;
  chatMessengerSelectorHeight: number;
  chatMessageMaxLines: number;
  baseChatHeight: number;
  chatHeight: number;
  chatSendingError: boolean;
  chatsLoading: boolean;
}

const initialState: ChatState = {
  numbers: [],
  contacts: [],
  chats: [],
  agents: [],
  clientNumber: '',
  showAgents: false,
  contactsPageSize: 5,
  contactsAllElements: 0,
  chatsPageSize: 5,
  messagesPageSize: 20,
  messagesAllElements: 0,
  selectedContact: '',
  number: '',
  channel: 'SMS',
  loading: false,
  error: '',
  serviceDisabled: false,
  mobileMessageView: true,
  messages: [],
  to: '',
  text: '',
  sending: false,
  contactSuggestions: [],
  payloadType: payloadType.TEXT,
  attachment: {
    uploading: false,
    data: undefined,
    id: undefined,
    validSize: true,
    failed: false,
    source: null,
  },
  attachDialOpen: false,
  loadingMessages: false,
  newMessages: {
    SMS: [],
    WB: [],
  },
  notificationBody: 'You received new message',
  notificationIgnore: true,
  notificationIcon: smsIcon,
  scrollToBottom: true,
  totalUnread: {},
  searchResults: searchResultsType.ALL,
  filter: '',
  loadingChatContacts: false,
  addNewContactDialogOpen: false,
  addNewUserFullFormOpen: false,
  validationError: false,
  validationMessage: '',
  messageMaxLength: 500,
  chatMessageLineHeight: 19,
  chatMessengerSelectorHeight: chatMessengerSelectorHeight,
  chatMessageMaxLines: 4,
  baseChatHeight:
    appConfig.SHOW_MESSENGER_SELECTOR === 'true'
      ? baseChatHeight
      : baseChatHeight - chatMessengerSelectorHeight,
  chatHeight:
    appConfig.SHOW_MESSENGER_SELECTOR === 'true'
      ? baseChatHeight
      : baseChatHeight - chatMessengerSelectorHeight,
  chatSendingError: false,
  chatsLoading: false,
};

export default function reducer(state: ChatState = initialState, action: AnyAction): ChatState {
  switch (action.type) {
    case 'SET_TOTAL_UNREAD': {
      return {
        ...state,
        totalUnread: action.payload,
      };
    }
    case 'SET_SCROLL_TO_BOTTOM': {
      return {
        ...state,
        scrollToBottom: action.payload,
      };
    }
    case 'INDICATE_NEW_MESSAGES': {
      return {
        ...state,
        newMessages: {
          ...state.newMessages,
          [action.payload.channel]: action.payload.data,
        },
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'FETCH_NUMBERS_SUCCESS': {
      return {
        ...state,
        loading: false,
        numbers: action.payload,
      };
    }
    case 'SET_NUMBER': {
      return {
        ...state,
        loading: false,
        number: action.payload,
      };
    }
    case 'SET_MOBILE_MESSAGE_VIEW': {
      return {
        ...state,
        loading: false,
        mobileMessageView: action.payload,
      };
    }
    case 'SET_NUMBERS': {
      return {
        ...state,
        numbers: [],
      };
    }
    case 'FETCH_NUMBERS_FAILURE': {
      return {
        ...state,
        loading: false,
        numbers: [],
        error: action.payload,
      };
    }
    case 'SET_CHANNEL': {
      if (['VB', 'FB'].includes(action.payload)) {
        return {
          ...state,
          serviceDisabled: true,
        };
      } else
        return {
          ...state,
          channel: action.payload,
          to: '',
          selectedContact: '',
        };
    }
    case 'SET_SERVICE_DISABLED': {
      return {
        ...state,
        serviceDisabled: action.payload,
      };
    }
    case 'ADD_MESSAGE': {
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    }
    case 'UPDATE_MESSAGES': {
      return {
        ...state,
        messages: [...action.payload],
      };
    }
    case 'SET_TEXT': {
      return {
        ...state,
        text: action.payload,
      };
    }
    case 'SET_TO': {
      return {
        ...state,
        to: action.payload,
      };
    }
    case 'SET_SENDING': {
      return {
        ...state,
        sending: action.payload,
      };
    }
    case 'SET_SELECTED_CONTACT': {
      return {
        ...state,
        selectedContact: action.payload,
      };
    }
    case 'SET_CONTACTS': {
      return {
        ...state,
        contacts: action.payload,
      };
    }
    case 'SET_CONTACTS_ALL_ELEMENTS': {
      return {
        ...state,
        contactsAllElements: action.payload,
      };
    }
    case 'SET_FILE_CHAT': {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          data: action.payload,
          validSize: validSize(action.payload.size, state.payloadType),
        },
      };
    }
    case 'SET_FILE_UPLOADING_CHAT': {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          uploading: true,
        },
      };
    }
    case 'SET_FILE_UPLOADED': {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          uploading: false,
          id: action.payload,
        },
      };
    }
    case 'SET_ATTACH_DIAL_OPEN': {
      return {
        ...state,
        attachDialOpen: action.payload,
      };
    }
    case 'SET_PAYLOAD_TYPE': {
      return {
        ...state,
        payloadType: action.payload,
      };
    }
    case 'CLEAR_ATTACHMENT': {
      return {
        ...state,
        attachment: {
          uploading: false,
          data: undefined,
          mpId: undefined,
          failed: false,
          id: undefined,
          validSize: true,
          source: null,
        },
        payloadType: payloadType.TEXT,
      };
    }
    case 'SET_ATTACHMENT_UPLOAD_FAILED': {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          failed: true,
          uploading: false,
          error: action.payload,
        },
      };
    }

    case 'SET_LOADING_MESSAGES': {
      return {
        ...state,
        loadingMessages: action.payload,
      };
    }
    case 'SET_ATTACHMENT_SOURCE': {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          source: action.payload,
        },
      };
    }
    case 'SET_NOTIFICATION': {
      return {
        ...state,
        notificationBody: action.payload.body,
        notificationIgnore: action.payload.ignore,
        notificationIcon: action.payload.icon,
      };
    }
    case 'SET_NOTIFICATION_IGNORE': {
      return {
        ...state,
        notificationIgnore: action.payload,
      };
    }
    case 'SET_CHAT_CONTACTS_PAGE_SIZE': {
      return {
        ...state,
        contactsPageSize: action.payload,
      };
    }
    case 'SET_CHAT_CHATS_PAGE_SIZE': {
      return {
        ...state,
        chatsPageSize: action.payload,
      };
    }
    case 'SET_CHATS': {
      return {
        ...state,
        chats: action.payload,
      };
    }
    case 'SET_SEARCH_RESULTS_TYPE': {
      return {
        ...state,
        searchResults: action.payload,
      };
    }
    case 'SET_CHAT_LOADING_CONTACTS': {
      return {
        ...state,
        loadingChatContacts: action.payload,
      };
    }
    case 'SET_CHAT_CONTACTS_FILTER': {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case 'SET_ADD_NEW_CONTACT_DIALOG_OPEN': {
      return {
        ...state,
        addNewContactDialogOpen: action.payload,
      };
    }
    case 'SET_ADD_NEW_USER_FULL_FORM_OPEN': {
      return {
        ...state,
        addNewUserFullFormOpen: action.payload,
      };
    }

    case 'SET_CHAT_VALIDATION_ERROR': {
      return {
        ...state,
        validationError: action.payload.flag,
        validationMessage: action.payload.message,
      };
    }

    case 'SET_CHAT_HEIGHT': {
      return {
        ...state,
        chatHeight: action.payload,
      };
    }
    case 'SET_CHAT_SENDING_ERROR': {
      return {
        ...state,
        chatSendingError: action.payload,
      };
    }
    case 'SET_SHOW_AGENTS': {
      return {
        ...state,
        showAgents: action.payload,
      };
    }
    case 'SET_AGENTS': {
      return {
        ...state,
        agents: action.payload,
      };
    }
    case 'SET_CHAT_NUMBER': {
      return {
        ...state,
        clientNumber: action.payload,
      };
    }
    case 'SET_CHATS_LOADING': {
      return {
        ...state,
        chatsLoading: action.payload,
      };
    }
    case 'SET_MESSAGES_PAGE_SIZE': {
      return {
        ...state,
        messagesPageSize: action.payload,
      };
    }
    case 'SET_MESSAGES_ALL_ELEMENTS': {
      return {
        ...state,
        messagesAllElements: action.payload,
      };
    }
    case 'RESET_MESSAGES_PAGE_SIZE': {
      return {
        ...state,
        messagesPageSize: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
