/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { Authorizations } from '../../types/login';

type Props = {
  readonly role: Authorizations;
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpAccount = (props: Props) => {
  const { role } = props;
  const isAdmin = role['SUPER_ADMIN'] || role['RESELLER_ADMIN'] || role['RESELLER_ADMIN_VIEWER'];
  return (
    <div style={{ padding: 5 }}>
      {!isAdmin && (
        <React.Fragment>
          <Typography variant="body1" component="div" style={{ marginTop: 15, marginBottom: 10 }}>
            {props.fromDashboard ? (
              <Button component={Link} to="/account/profile" size="small" color="secondary">
                <FormattedMessage id="help.account.1" />
              </Button>
            ) : (
              <Typography variant={'subtitle2'} style={{ marginBottom: 10, marginTop: 15 }}>
                <FormattedMessage id="help.account.1" />
              </Typography>
            )}
          </Typography>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Typography variant="subtitle2" align="justify">
              <FormattedMessage id="help.account.2" />
            </Typography>
          </div>
          <Typography variant="body1" component="div" style={{ marginTop: 15, marginBottom: 10 }}>
            {props.fromDashboard ? (
              <Button component={Link} to="/account/tokens" size="small" color="secondary">
                <FormattedMessage id="help.account.11" />
              </Button>
            ) : (
              <Typography variant={'subtitle2'} style={{ marginBottom: 10, marginTop: 15 }}>
                <FormattedMessage id="help.account.11" />
              </Typography>
            )}
          </Typography>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.account.12" />
            </Typography>
          </div>
          <Typography style={{ marginTop: 15, marginBottom: 10 }} variant="body1" component="div">
            {props.fromDashboard ? (
              <Button component={Link} to="account/invoices" size="small" color="secondary">
                <FormattedMessage id="help.account.9" />
              </Button>
            ) : (
              <Typography variant={'subtitle2'}>
                <FormattedMessage id="help.account.9" />
              </Typography>
            )}
          </Typography>
        </React.Fragment>
      )}
      {isAdmin ? (
        <React.Fragment>
          <Typography style={{ marginTop: 15, marginBottom: 15, marginLeft: 0 }} variant="body1" component="div">
            {props.fromDashboard ? (
              <Button component={Link} to="/account/profile" size="small" color="secondary">
                <FormattedMessage id="help.account.3" />
              </Button>
            ) : (
              <Typography variant={'subtitle2'} style={{ marginBottom: 10, marginTop: 15 }}>
                <FormattedMessage id="help.account.3" />
              </Typography>
            )}
          </Typography>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.admin.profile" />
            </Typography>
          </div>
        </React.Fragment>
      ) : (
        <div style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.account.4" />
          </Typography>
        </div>
      )}
      {!isAdmin && (
        <React.Fragment>
          <Typography style={{ marginTop: 15, marginBottom: 15 }} variant="body1" component="div">
            {props.fromDashboard ? (
              <Button component={Link} to="account/pricing" size="small" color="secondary">
                <FormattedMessage id="help.account.5" />
              </Button>
            ) : (
              <Typography variant={'subtitle2'}>
                <FormattedMessage id="help.account.5" />
              </Typography>
            )}
          </Typography>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.account.6" />
            </Typography>
          </div>
          <Typography style={{ marginTop: 15, marginBottom: 15 }} variant="body1" component="div">
            {props.fromDashboard ? (
              <Button component={Link} to="account/balance" size="small" color="secondary">
                <FormattedMessage id="help.account.7" />
              </Button>
            ) : (
              <Typography variant={'subtitle2'} style={{ marginBottom: 10, marginTop: 15 }}>
                <FormattedMessage id="help.account.7" />
              </Typography>
            )}
          </Typography>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.account.8" />
            </Typography>
          </div>
          <Typography style={{ marginTop: 15, marginBottom: 15 }} variant="body1" component="div">
            {props.fromDashboard ? (
              <Button component={Link} to="account/cdrs" size="small" color="secondary">
                <FormattedMessage id="help.account.13" />
              </Button>
            ) : (
              <Typography variant={'subtitle2'} style={{ marginBottom: 10, marginTop: 15 }}>
                <FormattedMessage id="help.account.13" />
              </Typography>
            )}
          </Typography>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.account.14" />
            </Typography>
          </div>
          <p></p>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.account.15" />
            </Typography>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state: AppState) {
  return {
    role: state.login.userData.authorizations,
  };
}

export default connect(mapStateToProps, {})(HelpAccount);
