import { AnyAction } from 'redux';
import { Campaign } from '../types/campaign';

interface CampaignHistoryState {
  campaigns: Campaign[];
  selectedCampaign: $TSFixMe;
  campaignLoading: boolean;
  campaignsLoading: boolean;
}

const initialState: CampaignHistoryState = {
  campaigns: [],
  selectedCampaign: {},
  campaignLoading: false,
  campaignsLoading: false,
};

export default function reducer(state: CampaignHistoryState = initialState, action: AnyAction): CampaignHistoryState {
  switch (action.type) {
    case 'SET_CAMPAIGNS_LOADING': {
      return {
        ...state,
        campaignsLoading: action.payload,
      };
    }
    case 'SET_CAMPAIGNS': {
      return {
        ...state,
        campaigns: action.payload,
      };
    }
    case 'SET_CAMPAIGN_LOADING': {
      return {
        ...state,
        campaignLoading: action.payload,
      };
    }
    case 'REMOVE_CAMPAIGN': {
      return {
        ...state,
        campaigns: state.campaigns.filter((item) => item.id !== action.payload),
      };
    }

    case 'SET_CAMPAIGN': {
      return {
        ...state,
        selectedCampaign: action.payload,
      };
    }
    
    default:
      return {
        ...state,
      };
  }
}
