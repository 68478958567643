import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Download from '@mui/icons-material/GetApp';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, Paper, Button, IconButton } from '@mui/material';
import Loading from '../../../common/Loading';
import { connect } from 'react-redux';
import {
  setExportMessageLogFormOpen,
  setExportFiltersForForm,
  getExportMessageLogFiles,
  deleteExportMessageLogFile,
} from '../../../../actions/messageLog';
import confirm from '../../../common/confirm';
import { reset } from 'redux-form';
import { saveAs } from 'file-saver';
import axios from 'axios/index';
import { format, parseISO } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import LocalizedMaterialTable from '../../../common/LocalizedMaterialTable';
import getTranslatedStatus from '../../../common/rowStatuses';
import { Column, Components } from '@material-table/core';
import { AppState } from '../../../../reducers';
import { Authorizations } from '../../../../types/login';
import { FormAction } from 'redux-form/lib/actions';
import ExportValidationConfig from './ExportValidationConfig';

interface Row {
  readonly id: $TSFixMe;
  readonly status: string;
  readonly fileName: string;
  readonly fileSize: number;
  readonly createdDate: string;
  readonly _links: {};
};

type Props = {
  readonly messageLogs: $TSFixMe[];
  readonly loading: boolean;
  readonly role: Authorizations;
  readonly filtersForForm: object;
  readonly exportMessageLogFormOpen: boolean;
  readonly getExportMessageLogFiles: () => void;
  readonly reset: (form: string) => FormAction;
  readonly deleteExportMessageLogFile: (id: $TSFixMe) => void;
  readonly setExportMessageLogFormOpen: (open: boolean) => void;
  readonly setExportFiltersForForm: (filter?: $TSFixMe) => void;
};

const MessageLogsExportHistory = ({
  role,
  loading,
  messageLogs,
  filtersForForm,
  exportMessageLogFormOpen,
  reset,
  getExportMessageLogFiles,
  deleteExportMessageLogFile,
  setExportFiltersForForm,
  setExportMessageLogFormOpen,
}: Props) => {
  const [state, setState] = useState({ actionsLoading: false });
  const intl = useIntl();

  useEffect(() => {
    getExportMessageLogFiles();
    const interval = setInterval(() => getExportMessageLogFiles(), 15000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (role['USER'] && Object.keys(filtersForForm).length > 0) {
      setExportMessageLogFormOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSize = (nBytes: number) => {
    let sOutput = nBytes + ' bytes';
    // optional code for multiples approximation
    const aMultiples = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    for (
      let nMultiple = 0, nApprox = nBytes / 1000;
      nApprox > 1;
      nApprox /= 1000, nMultiple++
    ) {
      sOutput = nApprox.toFixed(2) + ' ' + aMultiples[nMultiple];
    }
    return sOutput;
  };

  const handleDownload = (messageLogs: $TSFixMe) => {
    setState({
      actionsLoading: true,
      [`${messageLogs.fileName}Loading${messageLogs.id}`]: true,
    });
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios({
      url: messageLogs._links.download.href,
      headers: config.headers,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      if (response.status === 200)
      setState({
        actionsLoading: false,
        [`${messageLogs.fileName}Loading${messageLogs.id}`]: false,
      });
      saveAs(new Blob([response.data]), messageLogs.fileName);
    });
  };

  const handleDelete = (row: $TSFixMe) => {
    confirm(intl.formatMessage({ id: 'data.fetching.confirmation' }), {
      okLabel: intl.formatMessage({ id: 'data.fetching.confirmation.yes' }),
      cancelLabel: intl.formatMessage({ id: 'data.fetching.confirmation.no' }),
      title: intl.formatMessage({ id: 'data.fetching.confirmation.title' }),
    }).then(
      () => deleteExportMessageLogFile(row),
      () => {}
    );
  };

  const handleClose = () => {
    reset('exportValidationForm');
    setExportFiltersForForm({});
    setExportMessageLogFormOpen(false);
  };

  const prepareComponents = () => {
    let components: Components = {
      Container: ({ children }) => {
        return (
          <Paper elevation={role['USER'] ? 0 : 1}>
            <Loading loading={loading}>
              <>{children}</>
            </Loading>
          </Paper>
        );
      },
    };
      Object.assign(components, {
        Action: (props: any) => {
          if (props.action.icon === 'export') {
            return (
              <Button onClick={(event) => props.action.onClick(event, props.data)}>
                <FormattedMessage id="common.button.export" />
              </Button>
            );
          };
          if (props.action.icon === 'refresh') {
            return (
              <Button onClick={(event) => props.action.onClick(event, props.data)}>
                <FormattedMessage id="common.button.refresh" />
              </Button>
            );
          }
        },
      });
    return components;
  };

  const prepareActions = () => {
    let actions = [
      {
        icon: 'export',
        isFreeAction: true,
        onClick: () => {
          reset('exportValidationForm');
          setExportFiltersForForm({});
          setExportMessageLogFormOpen(true);
        },
      },
      {
        icon: 'refresh',
        isFreeAction: true,
        onClick: () => {
          getExportMessageLogFiles();
        },
      },
    ];
    return actions;
  };

  const prepareColumns = () => {
    let columns: Column<Row>[] = [
      {
        title: <FormattedMessage id="cdrs.export.table.date" />,
        field: 'createdDate',
        defaultSort: 'desc',
        render: (row: Row) => format(parseISO(row.createdDate), 'yyyy-MM-dd HH:mm:ss')
      },
      {
        title: <FormattedMessage id="cdrs.export.table.file.name" />,
        field: 'fileName',
      },
      {
        title: <FormattedMessage id="cdrs.export.table.size" />,
        field: 'fileSize',
        render: (row: Row) => row.fileSize ? getSize(row.fileSize) : ""
      },
      {
        title: <FormattedMessage id="common.label.status" />,
        field: 'status',
        render: (row: Row) => getTranslatedStatus(row.status),
      },
      {
        title: <FormattedMessage id="common.label.actions" />,
        width: 80,
        render: (row: Row) => {
          // @ts-expect-error TODO: not matching type string
          const { [`${row.fileName}Loading${row.id}`]: actionsLoading } = state;
          return (
            <>
              <IconButton
                style={{ height: 32, width: 32, padding: 0 }}
                onClick={() => handleDownload(row)}
                disabled={actionsLoading || row.status !== 'READY'}
              >
                {actionsLoading ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  // @ts-expect-error TODO: not assignable type
                  <Download color={row.status !== 'READY' ? '#00000024' : 'primary'} />
                )}
              </IconButton>
              {!role['RESELLER_ADMIN_VIEWER'] && (
                <IconButton
                  style={{ height: 32, width: 32, padding: 0 }}
                  onClick={() => handleDelete(row)}
                  disabled={!row._links}
                >
                  {/* @ts-expect-error TODO: not assignable type */}
                  <DeleteIcon color={!row._links ? '#00000024' : 'primary'} />
                </IconButton>
              )}
            </>
          );
        },
      }
    ];
  return columns;
  };

  return (
    <React.Fragment>
      <LocalizedMaterialTable
        title={
          <Typography variant="h5" component="span">
            <FormattedMessage id="cdrs.summary.table.title.export.history" />
          </Typography>
        }
        columns={prepareColumns()}
        data={messageLogs || []}
        components={prepareComponents()}
        options={{
          // @ts-expect-error TODO: defaultGroupOrder does not exist in options declaration https://material-table.com/#/docs/all-props
          defaultGroupOrder: 0,
          pageSize: 10,
          padding: 'dense',
          searchFieldStyle: { margin: '40px' },
          rowStyle: {
            fontSize: '0.8125rem',
            padding: '0px',
          },
          cellStyle: {
            padding: '2px 16px 1px 16px',
            height: '36px',
            maxHeight: '36px',
            whiteSpace: 'noWrap',
          },
          paginationPosition: 'top',
          headerStyle: { position: 'sticky', top: 0, zIndex: 100 },
          maxBodyHeight: 466,
        }}
        actions={prepareActions()}
      />
      <ExportValidationConfig
        open={exportMessageLogFormOpen}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
};

export default connect(
  (store: AppState) => {
    return {
      messageLogs: store.messageLog.exportMessageLogs,
      exportMessageLogFormOpen: store.messageLog.exportMessageLogFormOpen,
      loading: store.messageLog.loading,
      role: store.login.userData.authorizations,
      filtersForForm: store.messageLog.filtersForForm,
    };
  },
  {
    reset,
    getExportMessageLogFiles,
    setExportFiltersForForm,
    setExportMessageLogFormOpen,
    deleteExportMessageLogFile,  
  }
)(MessageLogsExportHistory);
