import React from 'react';
import { useIntl } from 'react-intl';
import MaterialTable, { MaterialTableProps } from '@material-table/core';

type Props<T extends object> = MaterialTableProps<T>;

const LocalizedMaterialTable = <T extends object>({
  data,
  title,
  actions,
  columns,
  options,
  components,
  tableRef,
  onSearchChange,
}: Props<T>) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        components={components}
        options={options}
        actions={actions}
        localization={{
          body: {
            emptyDataSourceMessage: intl.formatMessage({
              id: 'tables.no.records',
            }),
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: 'tables.displayed.rows',
            }),
            labelRowsSelect: intl.formatMessage({
              id: 'tables.rows.select',
            }),
            labelRowsPerPage: intl.formatMessage({
              id: 'tables.rows.per.page',
            }),
            firstTooltip: intl.formatMessage({
              id: 'tables.first.page.tooltip',
            }),
            previousTooltip: intl.formatMessage({
              id: 'tables.previous.page.tooltip',
            }),
            nextTooltip: intl.formatMessage({
              id: 'tables.next.page.tooltip',
            }),
            lastTooltip: intl.formatMessage({
              id: 'tables.last.page.tooltip',
            }),
          },
          toolbar: {
            searchTooltip: intl.formatMessage({
              id: 'tables.search',
            }),
            searchPlaceholder: intl.formatMessage({
              id: 'tables.search',
            }),
          },
        }}
        tableRef={tableRef}
        onSearchChange={onSearchChange}
      />
    </React.Fragment>
  );
};

export default LocalizedMaterialTable;
