/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpChat = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography variant="body1" style={{ marginTop: 15, marginBottom: 10 }}>
        {props.fromDashboard ? (
          <Button component={Link} to="/smschat" size="small" color="secondary">
            <FormattedMessage id="nav.smschat" />
          </Button>
        ) : undefined}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.smschat.2" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.2.1" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.3" />
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.4" />
        </Typography>
        <br />
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.smschat.5" />
        </Typography>
        <br />
        <Typography variant="body2" align="justify" >
          <FormattedMessage id="help.smschat.6" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.7" />
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.8" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.9" />
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.10" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.smschat.11" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.12" />
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.13" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.smschat.14" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.15" />
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.16" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.17" />
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.18" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.19" />
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.20" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.21" />
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="help.smschat.22" />
        </Typography>
      </div>
    </div>
  );
};

export default HelpChat;
