import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage } from 'react-intl';
import Loading from '../common/Loading';
import union from 'lodash-es/union';
import DataTableHead from './DataTableHead';
import DataTableToolbar from './DataTableToolbar';
import { Divider, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: 'auto',
    maxHeight: 520,
  },
  flexContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  checkedRow: {
    '&.MuiTableRow-root.Mui-selected': {
      backgroundColor: '#5a166614',
    },
  },
}));

type Props = {
  readonly title?: string | object;
  readonly page: $TSFixMe;
  readonly loading: boolean;
  readonly data: $TSFixMe[];
  readonly options: $TSFixMe;
  readonly columns: $TSFixMe[];
  readonly handleClose?: () => void;
  readonly handleColumnsSelection?: (id: $TSFixMe) => void;
};

const DataTable = (props: Props) => {
  const [columns, setColumns] = useState(props.columns);
  const [showSearchRow, setShowSearchRow] = useState(true);
  const [selected, setSelected] = useState(props.options.selectedRowsId || []);

  const handleColumnSelection = (id: $TSFixMe) => {
    const newColumns = columns.map((col) => {
      return col.id === id ? { ...col, visible: !col.visible } : { ...col };
    });
    setColumns(newColumns);
  };

  const handleSelectAllClick = (event?: React.SyntheticEvent, checked?: boolean) => {
    const { data } = props;
    if (checked) {
      const dataIds = data.map((n: $TSFixMe) => n.id);
      if (dataIds.every((elem: $TSFixMe) => selected.includes(elem))) {
        const unselected = selected.filter((item: $TSFixMe) => !dataIds.includes(item));
        setSelected(unselected);
      } else {
        setSelected(union(selected, dataIds));
      }
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event: React.SyntheticEvent, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: $TSFixMe = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    if (props.options.onRowSelect) {
      props.options.onRowSelect(newSelected);
    }
  };

  const clearIdsArray = () => {
    setSelected([]);
  };

  const handleShowSearchClick = () => {
    setShowSearchRow(!showSearchRow);
  };

  const classes = useStyles();
  const { data, title = '', page } = props;
  const {
    onClickNew,
    onSearch,
    onChangeRowsPerPage,
    onHandleChangePage,
    onHandleDelete,
    sort,
    onHandleSortChange,
    actions,
    extraButtons,
    onFilter,
    elevation = 2,
    showToolbar = true,
    showCheckbox = false,
    rowHeight = 36,
    actionLoading,
  } = props.options;

  return (
    <Paper elevation={elevation}>
      <Loading loading={props.loading}>
        <TablePagination
          component="div"
          count={page.totalElements || -1}
          rowsPerPage={page.size}
          page={page.number}
          onPageChange={onHandleChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={<FormattedMessage id="datatable.label.page.size" />}
          labelDisplayedRows={({ from, to, count }) => {
            const finalCount = count !== -1 ? count : to + 1;
            return (
              <FormattedMessage
                id="datatable.label.page.count"
                values={{ from: from, to: to, count: finalCount }}
              />
            )
          }}
          nextIconButtonProps={page.last ? { disabled: page.last } : undefined}
        />
        <Divider />
        {showToolbar && (
          <DataTableToolbar
            numSelected={selected.length}
            title={title}
            onClickNew={onClickNew}
            onSearch={onSearch}
            selected={selected}
            clearIdsArray={clearIdsArray}
            handleDelete={onHandleDelete}
            extraButtons={extraButtons}
            onSearchClick={handleShowSearchClick}
            columns={columns}
            handleColumnsSelection={handleColumnSelection}
            onFilter={onFilter}
            actions={actions && actions.renderMultipleActions}
          />
        )}

        <div className={classes.tableWrapper}>
          <Table stickyHeader>
            <DataTableHead
              numSelected={selected.length}
              order={sort.dir}
              orderBy={sort.by}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={onHandleSortChange}
              rowCount={page.totalElements}
              columnData={columns}
              onFilter={onFilter}
              showCheckbox={showCheckbox}
              actionLoading={actionLoading}
            />
            <TableBody>
              {data.map((row) => {
                let isElSelected = (id: number) => selected.indexOf(id) !== -1;
                const isSelected = isElSelected(row.id);
                return (
                  <TableRow
                    hover
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isSelected}
                    style={{ height: rowHeight }}
                    className={classes.checkedRow}
                  >
                    {showCheckbox && (
                      <TableCell
                        padding="checkbox"
                      // style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <Checkbox
                          // style={{ height: 32, width: 32 }}
                          color="secondary"
                          checked={isSelected}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                    )}
                    {columns.map((col: $TSFixMe) => {
                      if (col.visible) {
                        // const padding = col.id === 'id' ? false : col.disablePadding ? 'dense' : 'default';
                        return col.id === 'actions' ? (
                          <TableCell
                            key={col.id}
                            size="small"
                            align={col.align ? col.align : 'left'}
                            style={{
                              whiteSpace: 'nowrap',
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {actions.renderActions(row)}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={col.id}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingRight: 10,
                              whiteSpace: col.noWrap ? 'nowrap' : 'normal',
                              fontSize: '0.8125rem',
                              color: row['deleted'] ? 'lightgrey' : 'inherit',
                            }}
                            align={col.align ? col.align : 'left'}
                          >
                            {
                              // eslint-disable-next-line
                              col.render
                                ? col.render(row)
                                : typeof row[col.id] === 'boolean'
                                  ? `${row[col.id]}`
                                  : row[col.id]
                            }
                          </TableCell>
                        );
                      }
                      return <React.Fragment key={col.id} />;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Loading>
    </Paper>
  );
}

export default DataTable;
