import { AnyAction } from 'redux';

export const tabs = { config: 0, pricing: 1 };

interface WhatsappState {
  tab: number;
  tabOpen: boolean;
  customers: $TSFixMe[];
  customersForPricing: $TSFixMe[];
  configs: $TSFixMe[];
  pricings: $TSFixMe[];
  config: $TSFixMe;
  pricing: $TSFixMe;
  pricingSubmitResult: {
    error: boolean;
    message: string;
  };
  dateLimit?: $TSFixMe;
}

const initialState: WhatsappState = {
  tab: tabs.config,
  tabOpen: false,
  customers: [],
  customersForPricing: [],
  configs: [],
  pricings: [],
  config: {},
  pricing: {},
  pricingSubmitResult: { error: false, message: '' },
  dateLimit: undefined,
};

export default function reducer(state: WhatsappState = initialState, action: AnyAction): WhatsappState {
  switch (action.type) {
    case 'SET_WA_ADMINISTRATION_TAB': {
      return {
        ...state,
        tab: action.tab,
      };
    }
    case 'SET_WA_ADMINISTRATION_PRICING_SUBMIT_RESULT': {
      return {
        ...state,
        pricingSubmitResult: action.pricingSubmitResult,
      };
    }
    case 'SET_WA_ADMINISTRATION_TAB_OPEN': {
      return {
        ...state,
        tabOpen: action.tabOpen,
      };
    }
    case 'SET_WA_ADMINISTRATION_CUSTOMERS': {
      return {
        ...state,
        customers: action.customers,
      };
    }
    case 'SET_WA_ADMINISTRATION_CONFIGS': {
      return {
        ...state,
        configs: action.configs,
      };
    }
    case 'SET_WA_ADMINISTRATION_PRICINGS': {
      return {
        ...state,
        pricings: action.pricings,
      };
    }
    case 'SET_WA_ADMINISTRATION_CONFIG': {
      return {
        ...state,
        config: action.config,
      };
    }
    case 'SET_WA_ADMINISTRATION_PRICING': {
      return {
        ...state,
        pricing: action.pricing,
      };
    }
    case 'SET_WA_ADMINISTRATION_CUSTOMERS_FOR_PRICING': {
      return {
        ...state,
        customersForPricing: action.customers,
      };
    }
    case 'SET_WA_ADMINISTRATION_DATE_LIMIT': {
      return {
        ...state,
        dateLimit: action.dateLimit,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
