import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../actions/login';
import { RouteProps } from 'react-router';

type Props = Omit<RouteProps, 'component'> & {
  readonly isAuthenticated: () => Promise<void>;
  readonly component: React.ComponentType<RouteProps & { module?: React.ReactElement }>;
  readonly subComponent?: React.ComponentType;
};

const AuthenticatedRoute = ({
  component: Component,
  subComponent: SubComponent,
  ...rest
}: Props) => {
  rest.isAuthenticated();

  return (
    <Route
      {...rest}
      render={props => {
        if (localStorage.getItem('token')) {
          if (SubComponent) {
            return <Component {...props} module={<SubComponent />} />;
          }
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default connect(null, { isAuthenticated })(AuthenticatedRoute);
