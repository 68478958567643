import React from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { push } from 'react-router-redux';
import { appConfig } from '../config';
import translatedStatuses from '../components/common/translatedStatuses';
import { fetchLogo } from '../components/common/utils';
import { AppThunkAction } from './index';
import { MenuEntry } from '../types/menu';
import { MenuItem } from '../menu/MainMenuEntry';

export function getIpAddressInfo(): AppThunkAction {
  return (dispatch, getState) => {
    const state = { ...getState() };

    const tempDate = new Date().valueOf();
    if (
      state.app.ipInfo.ip &&
      // @ts-expect-error // TODO: get rid of magic number
      tempDate - state.app.ipInfo.checkTime < 79412287
    ) {
      return;
    }

    axios
      .get('https://ipapi.co/json')
      .then((res) => {
        dispatch({
          type: 'GET_IP_INFO',
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'GET_IP_INFO_FAILED',
          error: err,
        });
      });
  };
}

export function getMenu(): AppThunkAction {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  const url = `${appConfig.URL_REST}gui/menu`;
  return (dispatch) => {
    dispatch({
      type: 'GET_MENU_LOADING',
    });
    axios
      .get(url, config)
      .then((response) => {
        const menu: MenuEntry[] = Object.values(response.data);
        const sortFunction = (a: MenuEntry, b: MenuEntry) => a.order - b.order;
        menu.sort(sortFunction);
        menu.forEach((e: $TSFixMe) => {
          e.subMenu.sort(sortFunction);
        });
        menu.forEach((e: $TSFixMe) => {
          e.subMenu.forEach((inner: $TSFixMe) => (inner.parent = e.label));
        });
        dispatch({
          type: 'GET_MENU_SUCCESS',
          payload: menu,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'GET_MENU_FAILED',
          response: error,
        });
        dispatch({
          type: 'ADD_SNACKBAR',
          variant: 'error',
          msg: <FormattedMessage id="data.fetching.failed.menu" />,
        });
        dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
      });
  };
}

export function getLogo(): AppThunkAction {
  return (dispatch, getState) => {
    const { userData } = getState().login;
    const logoLink = userData._links && userData._links.logo;

    if (logoLink) {
      fetchLogo(logoLink.href)
        .then(logo => {
          dispatch(setLogo(logo));
        });
    }
  };
}

export function setLogo(logo: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOGO',
      payload: logo,
    });
  };
}

export function setMenu(menuItem: MenuItem): AppThunkAction {
  return (dispatch, getState) => {
    const { menuOpen } = getState().app;
    if (menuItem.link !== '#') {
      dispatch(push(menuItem.link));
      dispatch(setMenuOpen([menuItem.parent ? menuItem.parent : menuItem.id]));
      dispatch(setMenuSelected(menuItem.id));
      dispatch(closeMobileMenu());
    } else {
      if (menuOpen.includes(menuItem.id)) {
        dispatch(setMenuOpen([...menuOpen.filter((e) => e !== menuItem.id)]));
      } else {
        dispatch(setMenuOpen([...menuOpen, menuItem.id]));
      }
    }
  };
}

export function openMobileMenu(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'OPEN_MOBILE_MENU' });
  };
}

export function setMenuOpen(menuOpen: string[]): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_MENU_OPEN', payload: menuOpen });
  };
}

export function setMenuSelected(menuSelected: string): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_MENU_SELECTED', payload: menuSelected });
  };
}

export function closeMobileMenu(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_MOBILE_MENU' });
  };
}

export function setPerformanceDrawerShowFlag(flag: boolean): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_PERFORMANCE_DRAWER_SHOW_FLAG', payload: flag });
  };
}

export const fetchStatusList = (): AppThunkAction => {
  return (dispatch) =>
    axios
      .get(`${appConfig.URL_REST}dictionary/sms/status`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        const statusFilterOptions = Object.keys(res.data).map(status => {
          // @ts-expect-error // TODO: Fix after providing type
          const translationKey = translatedStatuses[status]
          return {
            value: status,
            name: translationKey || res.data[status],
          };
        });
        dispatch({
          type: 'SET_STATUS_FILTER_OPTIONS',
          payload: statusFilterOptions,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'SET_STATUS_FILTER_OPTIONS',
          payload: [],
        });
      });
};

export const fetchServiceList = (): AppThunkAction => {
  return (dispatch) =>
    axios
      .get(`${appConfig.URL_REST}dictionary/sms/service_type`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        const serviceFilterOptions = res.data.map((val: $TSFixMe) => {
          return { value: val, name: val };
        });
        dispatch({
          type: 'SET_SERVICE_FILTER_OPTIONS',
          payload: serviceFilterOptions,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'SET_SERVICE_FILTER_OPTIONS',
          payload: [],
        });
      });
};

export function fetchUnauthorizedStatusList(): AppThunkAction {
  return (dispatch) => {
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/unauthorized_mail/status_list`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        const unauthorizedFilterOptions = res.data.map((val: $TSFixMe) => {
          return { value: val, name: val };
        });
        dispatch({
          type: 'SET_UNAUTHORIZED_STATUS_FILTER_OPTIONS',
          payload: unauthorizedFilterOptions,
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: 'SET_UNAUTHORIZED_STATUS_FILTER_OPTIONS',
          payload: [],
        });
      });
  };
}

export function fetchCustomerTypeList(): AppThunkAction {
  return (dispatch) => {
    axios
      .get(`${appConfig.URL_REST}customers/customers_type`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        const customerTypeFilterOptions = res.data.map((val: $TSFixMe) => {
          return { value: val, name: val };
        });
        dispatch({
          type: 'SET_CUSTOMER_TYPE_FILTER_OPTIONS',
          payload: customerTypeFilterOptions,
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: 'SET_CUSTOMER_TYPE_FILTER_OPTIONS',
          payload: [],
        });
      });
  };
}

export const fetchSmsStatusList = (): AppThunkAction => {
  return (dispatch) =>
    axios
      .get(`${appConfig.URL_REST}sms/sms_status`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        const smsStatusFilterOptions = res.data.map((val: $TSFixMe) => {
          return { value: val, name: val };
        });
        dispatch({
          type: 'SET_SMS_STATUS_FILTER_OPTIONS',
          payload: smsStatusFilterOptions,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'SET_SMS_STATUS_FILTER_OPTIONS',
          payload: [],
        });
      });
};

export const fetchBillingCycleTypeList = (): AppThunkAction => {
  return (dispatch) =>
    axios
      .get(`${appConfig.URL_REST}customers/billing_cycle_type`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        const billingCycleTypeOptions = res.data.map((val: $TSFixMe) => {
          return { value: val, name: val };
        });
        dispatch({
          type: 'SET_BILLING_CYCLE_TYPE_OPTIONS',
          payload: billingCycleTypeOptions,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'SET_BILLING_CYCLE_TYPE_OPTIONS',
          payload: [],
        });
      });
};

export const fetchUserTagsList = (): AppThunkAction => {
  return (dispatch) => {
    axios
      .get(`${appConfig.URL_REST}customers/users/tags`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        const userTagsOptions = res.data.map((val: $TSFixMe) => {
          return { value: val, name: val };
        });
        dispatch({
          type: 'SET_USER_TAGS_OPTIONS',
          payload: userTagsOptions,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'SET_USER_TAGS_OPTIONS',
          payload: [],
        });
      });
  }
};

export const fetchFeeTypesList = (): AppThunkAction => {
  return (dispatch) =>
    axios
      .get(`${appConfig.URL_REST}price_list/pricing_fee_type`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        dispatch({
          type: 'SET_PRICING_FEE_TYPES_OPTIONS',
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'SET_PRICING_FEE_TYPES_OPTIONS',
          payload: [],
        });
      });
};
