import React from 'react';
import { FormattedMessage } from 'react-intl';
import { showSnackBarMsg } from '../../../../actions/snackbar';
import axios from 'axios';
import { appConfig } from '../../../../config';
import { format } from 'date-fns';
import { ExportValidationConfigFormSubmit } from './ExportValidationConfig';
import { getExportMessageLogFiles } from '../../../../actions/messageLog';

const submit: ExportValidationConfigFormSubmit = (values, dispatch, props) => {
  const { ...payload } = values;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  let filter = '';
  Object.keys(payload).forEach((key) => {
    const value = payload[key] instanceof Date ? format(payload[key], "yyyy-MM-dd'T'hh:mm") : payload[key];
    if (value !== null) filter += `&${key}=${value}`;
  });
  let url = `${appConfig.URL_REST}email2sms/message_logs/${props.uuid}/export?${filter.substring(1)}`;
  axios({
    url,
    headers: config.headers,
    method: 'GET',
    responseType: 'blob', // important
  })
  .then((res) => {
    if (res.status === 200) {
      dispatch(showSnackBarMsg(<FormattedMessage id="cdrs.get.request.success" />, 'success'));
      dispatch(getExportMessageLogFiles());
    }
  })
  .catch((error) => {
    console.error(error);
    dispatch(showSnackBarMsg(<FormattedMessage id="common.label.error" />, 'error'));
  });
};

export default submit;
