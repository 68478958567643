/* eslint max-len: 0 */

import React from 'react';
import { Typography, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import HelpCampaign from './HelpCampaign';
import HelpContacts from './HelpContacts';
import HelpAccount from './HelpAccount';
import HelpChat from './HelpChat';
import HelpQuickTestSms from './HelpQuickTestSms';
import { connect } from 'react-redux';
import HelpEmail2Sms from './HelpEmail2Sms';
import { AppState } from '../../reducers';
import { Authorizations } from '../../types/login';
import HelpAdministration from './HelpAdministration';
import HelpAdminMnp from './HelpAdminMnp';
import HelpAdminReports from './HelpAdminReports';

type Props = {
  readonly role: Authorizations;
  readonly company?: string;
  readonly helpEmail?: string;
};

const HelpDashboard = (props: Props) => {
  const { role } = props;
  const isAdmin = role['SUPER_ADMIN'] || role['RESELLER_ADMIN'] || role['RESELLER_ADMIN_VIEWER'];
  return isAdmin ? (
    <>
      <div style={{ padding: 15 }}>
        <Typography variant="h6" component="p" color="primary">
          <FormattedMessage id="help.dashboard.1" />
        </Typography>
        <Typography component="div" variant="subtitle2" style={{ margin: 15 }} align="justify">
          <p>
            <FormattedMessage id="help.admin.dashboard.1" />
          </p>
          <p>
            <FormattedMessage id="help.admin.dashboard.2" />
          </p>
          <p>
            <FormattedMessage id="help.admin.dashboard.3" />
          </p>
        </Typography>
        <Typography component="div" variant="body2" style={{ margin: 15 }}>
          <p>
            <FormattedMessage id="help.dashboard.6" />
          </p>
          <p>
            <FormattedMessage id="help.dashboard.7" />
          </p>
          <p>
            <FormattedMessage id="help.dashboard.8" />
            &nbsp;
            <a style={{ textDecoration: 'none', color: 'rgb(0, 122, 147)' }} href={props.helpEmail}>
              {props.helpEmail}
            </a>
          </p>
        </Typography>
        <Divider />
        <Typography component="div" style={{ marginTop: 15 }} variant="body2" align="justify">
          <Typography variant="h6" color="primary">
            <FormattedMessage id="help.dashboard.9" />
          </Typography>
          <div style={{ padding: 15 }}>
            <Typography variant="subtitle2">
              <FormattedMessage id="help.dashboard.10" />
            </Typography>
            <p>
              <FormattedMessage id="help.admin.dashboard.4" />
            </p>
            <p>
              <FormattedMessage id="help.admin.dashboard.5" />
            </p>
            <p>
              <FormattedMessage id="help.admin.dashboard.6" />
            </p>
            <p>
              <FormattedMessage id="help.admin.dashboard.7" />
            </p>
            <p>
              <FormattedMessage id="help.admin.dashboard.8" />
            </p>
            <p>
              <FormattedMessage id="help.admin.dashboard.9" />
            </p>
          </div>
        </Typography>
        <Divider />
        <Typography component="div" style={{ marginTop: 15 }}>
          <Typography variant="h6" color="primary">
            <FormattedMessage id="nav.account" />:
          </Typography>
          <HelpAccount fromDashboard="dash" />
        </Typography>
        <Divider />
        <Typography component="div" style={{ marginTop: 15 }}>
          <Typography variant="h6" color="primary">
            <FormattedMessage id="nav.administration" />:
          </Typography>
          <HelpAdministration fromDashboard="dash" />
        </Typography>
        <Divider />
        <Typography component="div" style={{ marginTop: 15 }}>
          <Typography variant="h6" color="primary">
            <FormattedMessage id="nav.mnp" />:
          </Typography>
          <HelpAdminMnp fromDashboard="dash" />
        </Typography>
        <Divider />
        <Typography component="div" style={{ marginTop: 15 }}>
          <Typography variant="h6" color="primary">
            <FormattedMessage id="nav.reports" />:
          </Typography>
          <HelpAdminReports fromDashboard="dash" />
        </Typography>
      </div>
    </>
  ) : (
    <div style={{ padding: 15 }}>
      <Typography variant="h6" component="p" color="primary">
        <FormattedMessage id="help.dashboard.1" />
      </Typography>
      <Typography component="div" variant="subtitle2" style={{ margin: 15 }} align="justify">
        <p>
          <FormattedMessage id="help.dashboard.2" />
        </p>
        <p>
          <FormattedMessage id="help.dashboard.3" />
        </p>
        <p>
          <FormattedMessage id="help.dashboard.4" />
        </p>
        <p>
          <FormattedMessage id="help.dashboard.5" />
        </p>
      </Typography>
      <Typography component="div" variant="body2" style={{ margin: 15 }}>
        <p>
          <FormattedMessage id="help.dashboard.6" />
        </p>
        <p>
          <FormattedMessage id="help.dashboard.6.1" />
        </p>
        <p>
          <FormattedMessage id="help.dashboard.7" />
        </p>
        <p>
          <FormattedMessage id="help.dashboard.8" />
          &nbsp;
          <a style={{ textDecoration: 'none', color: 'rgb(0, 122, 147)' }} href={props.helpEmail}>
            {props.helpEmail}
          </a>
        </p>
      </Typography>
      <Divider />
      <Typography component="div" style={{ marginTop: 15 }} variant="body2" align="justify">
        <Typography variant="h6" color="primary">
          <FormattedMessage id="help.dashboard.9" />
        </Typography>
        <div style={{ padding: 15 }}>
          <Typography variant="subtitle2">
            <FormattedMessage id="help.dashboard.10" />
          </Typography>
          <p>
            <FormattedMessage id="help.dashboard.9.1" />
          </p>
          <p>
            <FormattedMessage id="help.dashboard.9.2" />
          </p>
          <p>
            <FormattedMessage id="help.dashboard.9.3" />
          </p>
          <p>
            <FormattedMessage id="help.dashboard.9.4" />
          </p>
        </div>
      </Typography>
      <Divider />
      <Typography component="div" style={{ marginTop: 15 }}>
        <Typography variant="h6" color="primary">
          <FormattedMessage id="nav.smschat" />:
        </Typography>
        <HelpChat fromDashboard="dash" />
      </Typography>
      <Divider />
      <Typography component="div" style={{ marginTop: 15 }}>
        <Typography variant="h6" color="primary">
          <FormattedMessage id="nav.quickTestSms" />:
        </Typography>
        <HelpQuickTestSms fromDashboard="dash" />
      </Typography>
      <Divider />
      <Typography component="div" style={{ marginTop: 15 }}>
        <Typography variant="h6" color="primary">
          <FormattedMessage id="nav.email2sms" />:
        </Typography>
        <HelpEmail2Sms fromDashboard="dash" />
      </Typography>
      <Divider />
      <Typography component="div" style={{ marginTop: 15 }}>
        <Typography variant="h6" color="primary">
          <FormattedMessage id="nav.campaign" />:
        </Typography>
        <HelpCampaign fromDashboard="dash" />
      </Typography>
      <Divider style={{ marginTop: 1 }} />
      <Typography component="div" style={{ marginTop: 15 }}>
        <Typography variant="h6" color="primary">
          <FormattedMessage id="nav.contacts" />:
        </Typography>
        <HelpContacts fromDashboard="dash" />
      </Typography>
      <Divider style={{ marginTop: 15 }} />
      <Typography component="div" style={{ marginTop: 15 }}>
        <Typography variant="h6" color="primary">
          <FormattedMessage id="nav.account" />:
        </Typography>
        <HelpAccount fromDashboard="dash" />
      </Typography>
    </div>
  );
};

function mapStateToProps(state: AppState) {
  return {
    role: state.login.userData.authorizations,
  };
}

export default connect(mapStateToProps, {})(HelpDashboard);
