import axios from 'axios';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { format, fromUnixTime } from 'date-fns';
import { payloadType, searchResultsType } from '../reducers/chat';
import { getAccountInfo } from './login';
import { appConfig } from '../config';
import { debounce } from 'lodash-es';
import { parseISO } from 'date-fns';
import { extractIdFromHref } from '../components/common/utils';
import { showSnackBarMsg } from './snackbar';
import { AppThunkAction } from './index';
import { ChatAgent } from '../types/chatAgent';
import { ChatMessage, ChatMessagesPage } from '../types/chatMessage';
const waIcon = require('../chats/wa.png');
//const fbIcon = require('../chats/fb.png');
//const vbIcon = require('../chats/vb.png');
const smsIcon = require('../chats/sms.png');

interface FetchChatMessagesRequest {
  readonly phoneNumber: string;
  readonly clientNumber: string;
  readonly page: number;
  readonly size: number;
}

const stripPlus = (number: $TSFixMe) => {
  return number.startsWith('+') ? number.substring(1) : number;
};

export const getChatNumbers = (): AppThunkAction => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  return (dispatch, getState) => {
    const { channel } = getState().chat;
    dispatch({ type: 'SET_NUMBERS', payload: [] });
    dispatch({ type: 'SET_NUMBER', payload: null });
    let url = channel === 'WB' ? `${appConfig.URL_MP}` : `${appConfig.URL_REST}`;
    url = url.concat(`chat/numbers/${channel}`);
    axios
      .get(url, config)
      // .get(`${appConfig.URL_REST}chat/numbers/${channel}`, config)
      .then((res) => {
        dispatch({
          type: 'FETCH_NUMBERS_SUCCESS',
          payload: res.data,
        });
        if (res.data.length > 0) {
          dispatch({
            type: 'SET_NUMBER',
            payload: res.data[0],
          });
        } else {
          dispatch({ type: 'SET_SERVICE_DISABLED', payload: true });
          dispatch({ type: 'SET_CHANNEL', payload: 'SMS' });
        }
      })
      .catch((err) => {
        dispatch({
          type: 'FETCH_NUMBERS_FAILURE',
          payload: err.message,
        });
      });
  };
};

export const setChannel = (channel: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    if (channel) {
      if (channel === 'WB') {
        const hasWB = getState().login.userData.hasWB;
        if (!hasWB) {
          dispatch({ type: 'SET_SERVICE_DISABLED', payload: true });
        } else {
          dispatch({ type: 'SET_CHANNEL', payload: channel });
        }
      } else {
        dispatch({ type: 'SET_CHANNEL', payload: channel });
      }
    }
  };
};

export const setServiceDisabled = (flag: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_SERVICE_DISABLED', payload: flag });
  };
};

export const setMobileMessageView = (flag: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_MOBILE_MESSAGE_VIEW', payload: flag });
  };
};

export const addMessage = (message: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'ADD_MESSAGE', payload: message });
  };
};

export const updateMessages = (message: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    if (message.outgoing) {
      const { messages, channel } = getState().chat;
      if (channel === 'SMS') {
        dispatch({
          type: 'UPDATE_MESSAGES',
          payload: updateSMSmessageStatus(messages, message),
        });
      } else {
        dispatch({
          type: 'UPDATE_MESSAGES',
          payload: updateWBmessageStatus(messages, message),
        });
      }
    } else {
      const m = message.messenger === 'SMS' ? mapSMSMessage(message) : mapWBMessage(message);
      dispatch(updateContactsAndMessages(m));
    }
  };
};

export const sendMessage = (): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_SENDING',
      payload: true,
    });
    const {
      number: from,
      to,
      text,
      channel,
      payloadType: payload,
      attachment: { id, data },
      messages,
      baseChatHeight,
    } = getState().chat;
    const message = {
      from,
      to: channel === 'WB' ? (to.startsWith('+') ? to.substring(1) : to) : to,
      message: payload === payloadType.DOC ? data.name.substring(0, data.name.lastIndexOf('.')) || data.name : text,
      outgoing: true,
      attachmentId: id,
      payloadType: payload || payloadType.TEXT,
      attachmentFileName: data ? data.name : null,
      attachmentSize: data ? data.size : null,
    };
    const url = `${appConfig.URL_REST}chat/reply/${channel}`;
    // let url =
    //   channel === 'WB' ? `${appConfig.URL_MP}` : `${appConfig.URL_REST}`;
    // url = url.concat(`chat/reply/${channel}`);
    axios
      .post(url, message, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((response) => {
        dispatch(
          addMessage({
            ...message,
            date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            id: channel === 'SMS' ? response.data : undefined,
            ...response.data,
          })
        );
        dispatch(
          setReadFlag(
            messages.map((m) => (m.status === 'UNREAD' ? m.id : undefined)).filter((m) => typeof m === 'string'),
            true
          )
        );
        dispatch({ type: 'SET_SENDING', payload: false });
        dispatch({ type: 'SET_SCROLL_TO_BOTTOM', payload: true });
        dispatch(setChatHeight(baseChatHeight));
        dispatch(setText(''));
        dispatch(clearAttachment());
        dispatch(getAccountInfo());
        dispatch(moveContactToTopBySettingDate(to));
      })
      .catch((error) => {
        console.log(error);
        // @ts-expect-error TODO: clearAttachment function does not have any arguments - to verify!
        dispatch(clearAttachment(true));
        dispatch(setChatSendingError(true));
        dispatch({
          type: 'SET_SENDING',
          payload: false,
        });
      });
  };
};

export const setText = (text: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_TEXT', payload: text });
  };
};

const moveContactToTopBySettingDate = (number: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    const { chats } = getState().chat;
    const newChats = chats.map((c) => {
      if (c.number === number) return { ...c, lastMsgTime: new Date() };
      else return c;
    });
    dispatch({ type: 'SET_CHATS', payload: [...newChats] });
  };
};

export const setTo = (recipient: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_TO', payload: recipient });
  };
};

export const mapSMSMessage: $TSFixMeFunction = (m) => {
  return {
    from: m.sender,
    to: m.recipient,
    message: m.message,
    date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    id: m.msgId,
    messenger: 'SMS',
    status: 'UNREAD',
    outgoing: false,
    user: m.user,
  };
};

export const mapWBMessage: $TSFixMeFunction = (m) => {
  return {
    from: m.sender,
    to: m.recipient,
    message: m.payload.text,
    date: format(fromUnixTime(m.payload.timestamp), 'yyyy-MM-dd HH:mm:ss'),
    id: m.uuid,
    messenger: 'WB',
    status: 'UNREAD',
    outgoing: false,
    user: m.payload.user.name,
    attachmentId: m.payload.attachment,
    payloadType: m.payload.type,
    attachmentFileName: m.payload.filename,
    attachmentSize: m.payload.size,
    contactsJson: m.payload.contacts,
  };
};

export const setSelectedContact = (contact: $TSFixMe, update = true): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'RESET_MESSAGES_PAGE_SIZE', payload: 20 });
    if (update) {
      const { chats } = getState().chat;
      const newChats = chats.map((c) => {
        if (c.number === contact.number) return { ...c, unread: 0 };
        else return c;
      });
      dispatch({ type: 'SET_CHATS', payload: [...newChats] });
      dispatch({ type: 'SET_SELECTED_CONTACT', payload: contact });
    }
    dispatch({ type: 'SET_TO', payload: contact.number });
    dispatch({ type: 'SET_SCROLL_TO_BOTTOM', payload: true });
  };
};

export const getContacts = (): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_CHATS',
      payload: [],
    });
    dispatch({
      type: 'UPDATE_MESSAGES',
      payload: [],
    });

    const { number, channel, selectedContact } = getState().chat;

    if (number) {
      let url = channel === 'WB' ? `${appConfig.URL_MP}` : `${appConfig.URL_REST}`;
      url = url.concat(`chat/clients/${number}/${channel}`);
      // const url = `${appConfig.URL_MP}chat/clients/${number}/${channel}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        .then((response) => {
          if (response.status === 200) {
            const newMessages = response.data.map((e: $TSFixMe) => {
              return {
                number: e.number,
                count: e.countUnread,
                ids: [],
              };
            });
            dispatch({
              type: 'INDICATE_NEW_MESSAGES',
              payload: {
                data: [...newMessages],
                channel: channel,
              },
            });
            const data = response.data.length > 0 ? response.data : [];
            dispatch({
              type: 'SET_CHATS',
              payload: data.map((o: $TSFixMe) => ({
                ...o,
                lastMsgTime: o.lastMsgTime ? parseISO(o.lastMsgTime) : new Date(1970, 1, 1),
              })),
            });
            if (selectedContact !== '') {
              const c = data.filter(
                (value: $TSFixMe) => value.number === selectedContact
              );
              if (c) {
                dispatch(getMessages());
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
};

export const setNumber = (number: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_NUMBER', payload: number });
  };
};

const mapMessage = (msg: $TSFixMe): ChatMessage => {
  return {
    from: msg.sender,
    to: msg.receiver,
    id: msg.msgId,
    date: msg.receivedDate,
    message: msg.message,
    outgoing: msg.outgoing,
    origin: msg.user,
    messenger: msg.messenger,
    user: msg.user,
    payloadType: msg.payloadType,
    attachmentId: msg.attachmentId,
    attachmentFileName: msg.attachmentFileName,
    attachmentSize: msg.attachmentSize,
    contactsJson: JSON.parse(msg.contactsJson),
    status: msg.messenger === 'SMS' ? msg.status : msg.statusCode !== 200 ? 'error' : msg.status,
    statusCode: msg.statusCode,
    error: msg.error && JSON.parse(msg.error).error,
  };
};

export const setReadFlag = (ids: $TSFixMe, flag: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    const { messages, selectedContact, newMessages, channel } = getState().chat;
    const endpoint = flag ? 'mark_read' : 'mark_unread';
    let url = channel === 'WB' ? `${appConfig.URL_MP}` : `${appConfig.URL_REST}`;
    url = url.concat(`chat/${endpoint}/${channel}`);
    // const url = `${appConfig.URL_REST}chat/${endpoint}/${channel}`;
    axios
      .post(url, ids, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: 'SET_SCROLL_TO_BOTTOM',
            payload: false,
          });
          dispatch({
            type: 'UPDATE_MESSAGES',
            payload: messages.map((m) => (ids.includes(m.id) ? { ...m, status: flag ? 'READ' : 'UNREAD' } : { ...m })),
          });
          dispatch({
            type: 'INDICATE_NEW_MESSAGES',
            payload: {
              data: [
                // @ts-expect-error TODO: Fix after providing message model
                ...newMessages[channel].map((m: $TSFixMe) =>
                  m.number === selectedContact.number
                    ? {
                        ...m,
                        count: flag ? m.count - ids.length : m.count + ids.length,
                      }
                    : { ...m }
                ),
              ],
              channel: channel,
            },
          });
          dispatch(getChatCounter());
        }
      })
      .catch((error) => console.log(error));
  };
};

const fetchSmsChatMessages = (request: FetchChatMessagesRequest): Promise<ChatMessagesPage> => {
  const { phoneNumber, clientNumber, page, size } = request;

  return axios.get(`${appConfig.URL_REST}chat/${phoneNumber}/${clientNumber}/SMS?page=${page}&size=${size}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  }).then((response) => {
    const page = response.data.page;
    const content: ChatMessage[] = response.data._embedded.chatResources
      .map((e: $TSFixMe) => mapMessage(e));

    return {
      content,
      pageNumber: page.number,
      pageSize: page.size,
      totalElements: page.totalElements,
      totalPages: page.totalPages,
    };
  });
};

const fetchWbChatMessages = (request: FetchChatMessagesRequest): Promise<ChatMessagesPage> => {
  const { phoneNumber, clientNumber, page, size } = request;

  return axios.get(`${appConfig.URL_MP}chat/${phoneNumber}/${clientNumber}/WB?page=${page}&size=${size}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  }).then((response) => {
    const { number: pageNumber, size: pageSize, totalElements, totalPages } = response.data;
    const content: ChatMessage[] = response.data.content
      .map((e: $TSFixMe) => mapMessage(e));

    return {
      content,
      totalElements,
      totalPages,
      pageNumber,
      pageSize,
    };
  });
};

export const getMessages = (): AppThunkAction => {
  return (dispatch, getState) => {
    const { number, channel, selectedContact, messagesPageSize } = getState().chat;

    if (number !== '' && selectedContact !== '') {
      const chatMessagesRequest: FetchChatMessagesRequest = {
        phoneNumber: number,
        clientNumber: selectedContact.number,
        page: 0,
        size: messagesPageSize,
      };

      dispatch({ type: 'UPDATE_MESSAGES', payload: [] });
      dispatch(setLoadingMessages(true));

      const fetchChatMessages = channel === 'WB' ? fetchWbChatMessages : fetchSmsChatMessages;

      fetchChatMessages(chatMessagesRequest)
        .then((pagedChatMessages: ChatMessagesPage) => {
          if (pagedChatMessages.content) {
            dispatch({ type: 'UPDATE_MESSAGES', payload: pagedChatMessages.content });
            dispatch({
              type: 'SET_MESSAGES_ALL_ELEMENTS',
              payload: pagedChatMessages.totalElements,
            });
          }
          dispatch(setLoadingMessages(false));
        })
        .catch((error) => {
          console.log(error);
          dispatch(setLoadingMessages(false));
        });
    }
  };
};

export const getMoreMessages = (): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch(setLoadingMessages(true));
    const { messagesPageSize } = getState().chat;

    dispatch({
      type: 'SET_MESSAGES_PAGE_SIZE',
      payload: messagesPageSize + 10,
    });
    dispatch(setScrollToBottom(false));
    dispatch(getMessages());
  };
};

// TODO: Thunk action should have the getState function as 2nd arg but here it's state - to verify
const callApiForContacts: $TSFixMeFunction = (dispatch, state) => {
  const { contactsPageSize, filter } = state;

  dispatch(setChatContactsLoading(true));
  dispatch({ type: 'SET_CHATS_LOADING', payload: true });
  const filterUrl = `page=0&size=${contactsPageSize}&sort=firstName,asc&firstName=${filter}&lastName=${filter}&mobilePhone=${filter}`;
  const url = `${appConfig.URL_REST}contacts/or?${filterUrl}`;

  axios
    .get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    .then((response) => {
      if (
        response.data._embedded &&
        response.data._embedded.contacts &&
        response.data._embedded.contacts.length > 0
      ) {
        const contacts = response.data._embedded.contacts.map((c: $TSFixMe) => {
          const firstName = c.firstName || '';
          const lastName = c.lastName || '';
          return {
            name: (firstName + ' ' + lastName).trim(),
            number: c.mobilePhone,
          };
        });
        dispatch({ type: 'SET_CONTACTS', payload: contacts });
        dispatch({
          type: 'SET_CONTACTS_ALL_ELEMENTS',
          payload: response.data.page.totalElements,
        });
      } else {
        dispatch({ type: 'SET_CONTACTS', payload: [] });
        dispatch({
          type: 'SET_CONTACTS_ALL_ELEMENTS',
          payload: 0,
        });
      }
      dispatch(setChatContactsLoading(false));
      dispatch({ type: 'SET_CHATS_LOADING', payload: false });
    })
    .catch((error) => {
      console.error(error);
      dispatch(setChatContactsLoading(false));
      dispatch({ type: 'SET_CHATS_LOADING', payload: false });
    });
};

const callApiForContactsDebounced = debounce((_dispatch, state) => callApiForContacts(_dispatch, state), 500);

export const findContacts = (): AppThunkAction => {
  return (dispatch, getState) => {
    const state = getState().chat;
    callApiForContactsDebounced(dispatch, state);
  };
};

export const setFile = (file: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_FILE_CHAT', payload: file });
    const { validSize } = getState().chat.attachment;
    //upload the file if size is allowed
    if (validSize) {
      dispatch({ type: 'SET_FILE_UPLOADING_CHAT' });
      const url = `${appConfig.URL_REST}chat/media`;
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(url, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.status === 200) dispatch({ type: 'SET_FILE_UPLOADED', payload: res.data.id });
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.message &&
            JSON.parse(err.response.data.message.replaceAll('"{', '{').replaceAll('}"', '}').replaceAll('\\"', '"'))
              .message.error;
          dispatch(setAttachmentUploadFailed(error));
        });
    }
  };
};

export const setAttachDialOpen = (flag: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ATTACH_DIAL_OPEN', payload: flag });
  };
};

export const setPayloadType = (type: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_PAYLOAD_TYPE', payload: type });
  };
};

export const clearAttachment = (): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLEAR_ATTACHMENT' });
  };
};

export function setAttachmentUploadFailed(err: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ATTACHMENT_UPLOAD_FAILED', payload: err });
  };
}

export const setNotificationIgnore = (flag: boolean): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_NOTIFICATION_IGNORE', payload: flag });
  };
};

const updateContactsAndMessages = (message: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    const { chats, selectedContact, newMessages } = getState().chat;

    const contactInChats = chats.find((c) => stripPlus(c.number) === stripPlus(message.from));
    const { messenger } = message;
    // @ts-expect-error TODO: Fix after providing message model
    const contactInNewMessages = newMessages[messenger].find(
      (c: $TSFixMe) => stripPlus(c.number) === stripPlus(message.from)
    );
    if (contactInNewMessages) {
      dispatch({
        type: 'INDICATE_NEW_MESSAGES',
        payload: {
          data: [
            // @ts-expect-error TODO: Fix after providing message model
            ...newMessages[messenger].filter(
              (n: $TSFixMe) => stripPlus(n.number) !== stripPlus(message.from)
            ),
            {
              number: contactInNewMessages.number,
              count: contactInNewMessages.count + 1,
              ids: [...contactInNewMessages.ids, message.msgId],
            },
          ],
          channel: messenger,
        },
      });
    } else {
      dispatch({
        type: 'INDICATE_NEW_MESSAGES',
        payload: {
          data: [
            // @ts-expect-error TODO: Fix after providing message model
            ...newMessages[messenger],
            {
              number: message.from,
              count: 1,
              ids: [message.msgId],
            },
          ],
          channel: messenger,
        },
      });
    }
    dispatch(getChatCounter());
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        body: `You received new ${messenger} message`,
        ignore: false,
        icon: messenger === 'SMS' ? smsIcon : waIcon,
      },
    });

    dispatch({
      type: 'SET_NOTIFICATION_IGNORE',
      payload: true,
    });

    if (contactInChats) {
      contactInChats.unread = contactInChats.unread + 1;
      contactInChats.lastMsgTime = new Date();
      const newContacts = chats.map((c) => (c.number === contactInChats.number ? contactInChats : c));
      dispatch({
        type: 'SET_CHATS',
        payload: newContacts,
      });
    } else {
      dispatch({
        type: 'SET_CHATS',
        payload: [
          ...chats,
          {
            name: message.user,
            number: message.from,
            unread: 1,
            lastMsgTime: new Date(),
          },
        ],
      });
    }
    if (selectedContact && stripPlus(message.from) === stripPlus(selectedContact.number)) {
      dispatch(
        addMessage({
          ...message,
        })
      );
    }
  };
};

const updateWBmessageStatus: $TSFixMeFunction = (messages, message) =>
  messages.map((m: $TSFixMe) => {
    if (m.id === message.uuid) {
      if (message.statuscode === 500) {
        return {
          ...m,
          status: 'error',
          error: message.result && JSON.parse(message.result).error,
          date: format(fromUnixTime(message.processed), 'yyyy-MM-dd HH:mm:ss'),
        };
      }
      if (message.statuscode === 404) {
        return {
          ...m,
          status: 'error',
          error: 'Contact not found (out of 24h window)',
          date: format(fromUnixTime(message.sent), 'yyyy-MM-dd HH:mm:ss'),
        };
      }
      if (message.statuscode === 470) {
        return {
          ...m,
          status: 'error',
          error: 'Consent needed (out of 24h window)',
          date: format(fromUnixTime(message.sent), 'yyyy-MM-dd HH:mm:ss'),
        };
      }
      if (message.statuscode === 200) {
        if (message.read !== null) {
          return {
            ...m,
            status: 'read',
            date: format(fromUnixTime(message.read), 'yyyy-MM-dd HH:mm:ss'),
          };
        }
        if (message.received !== null) {
          return {
            ...m,
            status: 'received',
            date: format(fromUnixTime(message.received), 'yyyy-MM-dd HH:mm:ss'),
          };
        }
        if (message.sent !== null) {
          return {
            ...m,
            status: 'sent',
            date: format(fromUnixTime(message.sent), 'yyyy-MM-dd HH:mm:ss'),
          };
        }
      }
    }
    return m;
  });

const updateSMSmessageStatus: $TSFixMeFunction = (messages, message) =>
  messages.map((m: $TSFixMe) => {
    if (m.id === message.msgId) {
      return {
        ...m,
        status: message.status,
        date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };
    }

    return m;
  });

export const getAttachment = (id: $TSFixMe, media: $TSFixMe): AppThunkAction<Promise<$TSFixMe>> => {
  return (dispatch, getState) => {
    const url = `${appConfig.URL_REST}chat/attachment/${id}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((response) => {
        if (media === payloadType.AUDIO) return Promise.resolve(response);
        window.location = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const setAttachmentSource = (url: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ATTACHMENT_SOURCE', payload: url });
  };
};

export const setLoadingMessages = (flag: boolean): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_LOADING_MESSAGES', payload: flag });
  };
};

export const setScrollToBottom = (flag: boolean): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_SCROLL_TO_BOTTOM', payload: flag });
  };
};

export const getChatCounter = (): AppThunkAction => {
  return (dispatch, getState) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.URL_REST}chat/all_unread`, config)
      .then((res) => {
        dispatch({ type: 'SET_TOTAL_UNREAD', payload: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setFilter = (type: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_CHAT_CONTACTS_FILTER', payload: type });
  };
};

export const setChatContactsLoading = (flag: boolean): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_CHAT_LOADING_CONTACTS', payload: flag });
  };
};
export const setChatContactsPageSize = (size: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_CHAT_CONTACTS_PAGE_SIZE', payload: size });
  };
};

export const setAddNewContactDialogOpen = (flag: boolean): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ADD_NEW_CONTACT_DIALOG_OPEN', payload: flag });
  };
};

export const setAddNewUserFullFormOpen = (flag: boolean): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ADD_NEW_USER_FULL_FORM_OPEN', payload: flag });
  };
};

export const setSearchResults = (type: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch(setChatContactsLoading(true));
    const { filter } = getState().chat;
    dispatch({ type: 'SET_SEARCH_RESULTS_TYPE', payload: type });
    const contactsPageSize = type === searchResultsType.ALL ? 5 : 10;
    dispatch({
      type: 'SET_CHAT_CONTACTS_PAGE_SIZE',
      payload: contactsPageSize,
    });
    const chatsPageSize = type === searchResultsType.ALL ? 5 : 10000;
    dispatch({
      type: 'SET_CHAT_CHATS_PAGE_SIZE',
      payload: chatsPageSize,
    });
    // @ts-expect-error TODO: clearAttachment function does not have any arguments - to verify!
    dispatch(findContacts(filter));
  };
};

export const getMoreContacts = (): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch(setChatContactsLoading(true));
    const { filter, contactsPageSize } = getState().chat;

    dispatch({
      type: 'SET_CHAT_CONTACTS_PAGE_SIZE',
      payload: contactsPageSize + 10,
    });
    // @ts-expect-error TODO: clearAttachment function does not have any arguments - to verify!
    dispatch(findContacts(filter));
  };
};

export const setValidationError = (flag: $TSFixMe, message: $TSFixMe): AppThunkAction => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CHAT_VALIDATION_ERROR',
      payload: { flag, message },
    });
  };
};

export const setChatHeight = (height: $TSFixMe): AppThunkAction => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CHAT_HEIGHT',
      payload: height,
    });
  };
};

export const setChatSendingError = (flag: $TSFixMe): AppThunkAction => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CHAT_SENDING_ERROR',
      payload: flag,
    });
  };
};

export function getAgents(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_AGENTS_LOADING', payload: true });
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.URL_REST}users/chat-agents`, config)
      .then((res) => {
        const data = res.data.map((e: $TSFixMe) => {
          return {
            id: parseInt(extractIdFromHref(e.links.find((link: $TSFixMe) => link.rel === 'self').href)),
            name: (e.firstName + ' ' + e.lastName).trim(),
            number: e.phoneNumber,
            status: e.chatAgentStatus,
          };
        });
        dispatch(setAgents(data));
        dispatch({ type: 'SET_AGENTS_LOADING', payload: false });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: 'SET_AGENTS_LOADING', payload: false });
      });
  };
}

export function setAgents(agents: ChatAgent[]): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_AGENTS', payload: agents });
  };
}

export function assignChatToAgent(agent: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const phoneNumber = getState().chat.number;
    const clientNumber = getState().chat.clientNumber;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.URL_REST}chat/${phoneNumber}/${clientNumber}/SMS/agent?agentId=${agent}`, agent, config)
      .then((res) => {
        dispatch(getChatNumbers());
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((err) => {
        console.error(err);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function removeAgent(): AppThunkAction {
  return (dispatch, getState) => {
    const phoneNumber = getState().chat.number;
    const clientNumber = getState().chat.clientNumber;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.URL_REST}chat/${phoneNumber}/${clientNumber}/SMS/agent`, null, config)
      .then((res) => {
        dispatch(getChatNumbers());
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export const setChatNumber = (clientNumber: $TSFixMe): AppThunkAction => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CHAT_NUMBER',
      payload: clientNumber,
    });
  };
};

export const setShowAgents = (flag: boolean): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_SHOW_AGENTS', payload: flag });
  };
};

export function setAgentStatus(status: string): AppThunkAction {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.URL_REST}chat/agent/status`, { status: status }, config)
      .then((res) => {
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((err) => {
        console.error(err);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}
